import React from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { useModal } from '../Contex.js';
import { UPDATE_MOBILE } from '../graphql/Mutation/Signin.js';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GET_ROLE_USER } from '../graphql/Query/Signin.js';

const MobileUpdate = () => {
    const { modalOpen, setModalOpen } = useModal();
    const [updateMobile] = useMutation(UPDATE_MOBILE);
    const { data: getUser } = useQuery(GET_ROLE_USER);

    const formik = useFormik({
        initialValues: { phoneNumber: '' },
        validationSchema: Yup.object({
            phoneNumber: Yup.string()
                .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
                .required('Phone number is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const { data } = await updateMobile({
                    variables: {
                        "mobileNumberUpdateUserId": getUser?.me?.id,
                        mobile: values.phoneNumber,
                    },
                });

                if (data?.mobileNumberUpdateUser) {
                    setModalOpen(false)
                    toast.success('Mobile Number Added successfully!');
                    resetForm();
                }
            } catch (error) {
                toast.error(error.message);
            }
        },
    });

    const handleModalClose = () => {
        setModalOpen(false)
        formik.resetForm();
    };

    return (
        <div>
            {modalOpen && (
                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-65 transition-opacity"
                >
                    <DialogPanel className="bg-white rounded-lg max-w-sm w-full mx-4">
                        <div className="p-5">
                            <h4 className="font-bold text-gray-800 mb-7">Verify Your Mobile No.</h4>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="mb-4">
                                    <label
                                        htmlFor="phoneNumber"
                                        className="block mb-2 text-sm font-medium text-gray-700"
                                    >
                                        Mobile No.
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                            +91
                                        </span>
                                        <input
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            type="text"
                                            placeholder="Enter Mobile Number"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phoneNumber}
                                            className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                        />
                                    </div>
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                        <div className="text-red-600 text-sm mt-1">
                                            {formik.errors.phoneNumber}
                                        </div>
                                    ) : null}
                                </div>
                                <button
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    className="w-full rounded-md bg-slate-900 px-3 py-2.5 text-sm font-semibold text-white hover:bg-slate-900 sm:mt-1"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                        <div className="sticky bottom-0 bg-gray-50 px-4 py-3 sm:px-6 rounded-b-lg">
                            <button
                                type="button"
                                onClick={() => { handleModalClose() }}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </Dialog>
            )}
        </div>
    );
};

export default MobileUpdate;
