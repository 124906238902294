import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import React, { useState } from 'react'
import l7Image from '../asset/img/l77.jpg';
import ukv from '../asset/img/ukvaan.webp';
import l7video from '../asset/img/Untitled 1_1080p.mp4';

const ShipLabel = ({ open, setOpen, selectedLabel }) => {
    const handleDownload = (label) => {
        const link = document.createElement('a');
        link.href = label;
        link.download = 'label.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div>
            <Dialog open={open} onClose={() => {}} className="relative z-10">
                <div
                    transition
                    className="fixed inset-0 bg-black bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:ml-2 sm:mt-0 sm:text-left">
                                        <div className="mb-4">
                                            <h2 className="text-xl font-bold">UK Logistic Shipment Label Information</h2>
                                        </div>
                                        <div>
                                            <p className='font-semibold mb-2'>Step 1: Click to Download label</p>
                                            <button
                                                type="button"
                                                // onClick={() => setOpen(false)}
                                                onClick={() => { handleDownload(selectedLabel) }}
                                                className=" w-full justify-center rounded-md bg-slate-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-900"
                                            >
                                                Download Label
                                            </button>
                                        </div>
                                        <div className='mt-6'>
                                            <p className='font-semibold'>Step 2: Xerox (Shipment label) (Size 4 * 6 Inch)</p>
                                            <img src={l7Image} alt="" />
                                        </div>

                                        <div className='mt-6'>
                                            <p className='font-semibold mb-2'>Step 3: Paste shipment label on box</p>
                                            <video
                                                // controls
                                                autoPlay
                                                loop
                                                muted
                                                className="rounded-xl"
                                            >
                                                <source src={l7video} type="video/mp4" className='cursor-pointer' />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div className='mt-6'>
                                            <p className='font-semibold'>Step 4: Sit back and relax (Your order will be delivered to the chosen destination. Track it from our website or app)</p>
                                            <img src={ukv} alt=""  className='rounded-xl mt-4 h-80'/>
                                        </div>
                                        {/* <div className="mt-2">

                                            <iframe
                                                src={selectedLabel}
                                                title="Shipping Label"
                                            // className="w-full h-64 border"
                                            ></iframe>

                                        </div> */}
                                        {/* <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Deactivate account
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to deactivate your account? All of your data will be permanently removed.
                                                This action cannot be undone.
                                            </p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setOpen(false)}
                                    className="mt-3 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default ShipLabel
