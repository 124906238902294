import React from 'react';
import Header from '../Header/Header.js';
import Footer from './Footer.js';
import { Link } from 'react-router-dom';
import f33Image from '../asset/img/f33.jpg';

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <div className='relative'>
      <img src={f33Image} className='w-full' alt="Privacy Policy" />
        <p className='font-bold text-white text-2xl absolute bottom-6 left-6 z-30 p-4'>
          Terms & Conditions
        </p>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 py-12 lg:px-8">
        <p className="text-xl font-bold mb-3">Introduction</p>

        <div className="mb-8">
          <p className="">
            These Terms and Conditions, along with privacy policy or other terms (“Terms”) constitute a binding
            agreement by and between UK Logistic, ( “UK Logistic" or “we” or “us” or
            “our”) and you (“you” or “your”) and relate to your use of our website, goods (as applicable) or services
            (as applicable) (collectively, “Services”).
          </p>
          <p className='mt-3'>
            By using our website and availing the Services, you agree that you have read and accepted these Terms
            (including the Privacy Policy). We reserve the right to modify these Terms at any time and without
            assigning any reason. It is your responsibility to periodically review these Terms to stay informed of
            updates.
          </p>
        </div>

        <div>
          <div className='space-y-7'>
            <p className='font-semibold text-sm'>The use of this website or availing of our Services is subject to the following terms of use:</p>
            <p className='font-semibold'>● To access and use the Services, you agree to provide true, accurate and complete information to us
              during and after registration, and you shall be responsible for all acts done through the use of your
              registered account.
            </p>
            <p className='font-semibold'>● Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
              performance, completeness or suitability of the information and materials offered on this website
              or through the Services, for any specific purpose. You acknowledge that such information and
              materials may contain inaccuracies or errors and we expressly exclude liability for any such
              inaccuracies or errors to the fullest extent permitted by law.
            </p>
            <p className='font-semibold'>● Your use of our Services and the websiteis solely at your own risk and discretion.. You are
              required to independently assess and ensure that the Services meet your requirements.
            </p>
            <p className='font-semibold'>● The contents of the Website and the Services are proprietary to Us and you will not have any
              authority to claim any intellectual property rights, title, or interest in its contents.
            </p>
            <p className='font-semibold'>● You acknowledge that unauthorized use of the Website or the Services may lead to action against
              you as per these Terms or applicable laws.
            </p>
            <p className='font-semibold'>● You agree to pay us the charges associated with availing the Services.
            </p>
            <p className='font-semibold'>● You agree not to use the website and/ or Services for any purpose that is unlawful, illegal or
              forbidden by these Terms, or Indian or local laws that might apply to you.
            </p>
            <p className='font-semibold'>● You agree and acknowledge that website and the Services may contain links to other third party
              websites. On accessing these links, you will be governed by the terms of use, privacy policy and
              such other policies of such third party websites.
            </p>
            <p className='font-semibold'>● You understand that upon initiating a transaction for availing the Services you are entering into a
              legally binding and enforceable contract with the us for the Services.
            </p>
            <p className='font-semibold'>● You shall be entitled to claim a refund of the payment made by you in case we are not able to
              provide the Service. The timelines for such return and refund will be according to the specific
              Service you have availed or within the time period provided in our policies (as applicable). In case
              you do not raise a refund claim within the stipulated time, than this would make you ineligible for
              a refund.
            </p>
            <p className='font-semibold'>● Notwithstanding anything contained in these Terms, the parties shall not be liable for any failure to
              perform an obligation under these Terms if performance is prevented or delayed by a force majeure
              event.
            </p>
            <p className='font-semibold'>● These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and
              construed in accordance with the laws of India.
            </p>
            <p className='font-semibold'>● All disputes arising out of or in connection with these Terms shall be subject to the exclusive
              jurisdiction of the courts in PASODARA, Gujarat.
            </p>
            <p className='font-semibold'>● All concerns or communications relating to these Terms must be communicated to us using the
              contact information provided on this website.
            </p>
          </div>
        </div>

        <div className='mt-7'>
          <p className="font-bold mb-3">Eligibility</p>
          <p>You may use the Applications in accordance with our contractual arrangement with you and /or as per these Terms. You must be over the age of eighteen (18) years and able to understand and agree to the terms, conditions, obligations, representations, and warranties set forth in these Terms.</p>
        </div>

        <div className='mt-7'>
          <p className="font-bold mb-3">Access To And Use Of The Applications</p>
          <p>You are prohibited from accessing data not intended for you or logging onto a processor, or access device or account which you are not authorized to access. You shall not interfere with or disrupt the services for any other user, including, without limitation, by submitting a virus, or worm in the network. You shall not use the Applications to send any unsolicited e-mail or other information.</p>
        </div>

        <div className='mt-7'>
          <p className="font-bold mb-3">Indemnity And Release</p>
          <p>You shall indemnify and hold us harmless from any claim or demand, or actions including reasonable attorney’s fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms or any document incorporated by reference, or your violation of any law, rules, regulations, or the rights of a third party.</p>
        </div>

        <div className='mt-7'>
          <p className="font-bold mb-3">Communication</p>
          <p>When you visit our Applications or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Website. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
        </div>

        <div className='mt-7'>
          <p className="font-bold mb-3">Privacy Policy</p>
          <p>Our Privacy Policy outlines what personal data we collect when you sign-up with us or use our services, why we collect this data, and how we process that data to provide you with best-in class services and updates regarding your orders. You can access the <Link to='/privacyPolicy'><span className='text-blue-600 underline cursor-pointer'>Privacy Policy</span></Link> associated with the Applications to understand our use of your data.</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
