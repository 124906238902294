import React, { useEffect, useState } from 'react';
import AdminHeader from './AdminHeader.js';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ORDERS } from '../graphql/Query/Order.js';
import moment from 'moment';
import Loader from '../components/Loader.js';
import CustomPagination from '../components/Pagination.js';

const AdminTodayOrder = () => {
    const [searchUser, setSearchUser] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [filter, setFilter] = useState('');
    const [todayTicketCount, setTodayTicketCount] = useState(0);
    const [cancelCourier, setCancelCourier] = useState();
    const [cancelOrder, setCancelOrder] = useState(0);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(200);

    const { data, loading } = useQuery(GET_ORDERS, {
        variables: {
            "page": page + 1,
            "limit": limit,
            "sort": {
                "key": "createdAt",
                "type": -1
            },
            "filter": filter,
            "from": fromDate,
            "to": toDate,
            "orderStatus": cancelCourier

        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (data?.getOrders?.data) {
            const today = moment().startOf('day');
            const todayTickets = data?.getOrders?.data?.filter((ticket) => moment(ticket.createdAt).isSame(today, 'day'));

            const canceledOrder = data?.getOrders?.data?.filter((order) =>
                order.status === 'canceled' && moment(order.createdAt).isSame(today, 'day')
            );
            setCancelOrder(canceledOrder?.length);
            setTodayTicketCount(todayTickets?.length);
        }
    }, [data]);

    const fromDateChange = (e) => {
        setFromDate(e.target.value);
    }

    const toDateChange = (e) => {
        setToDate(e.target.value);
    }

    const searchCustomerUser = () => {
        setFilter(searchUser);
        setPage(0);
    }

    const handleSearch = (e) => {
        searchCustomerUser()
    }

    const handleReset = () => {
        setFromDate('');
        setToDate('');
        setFilter('');
        setSearchUser('');
        setCancelCourier('');
        setPage(0);
    }

    const handlePageClick = (selectedPage) => {
        setPage(selectedPage);
      };
    return (
        <div>
            <AdminHeader />
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 my-10 space-y-10">
                <div className='flex flex-wrap justify-between'>
                    <div>
                        <h2 className="text-lg font-semibold text-green-500">All Orders</h2>
                    </div>

                    <div className='flex mt-3 md:mt-0 flex-wrap'>
                        <h2 className="text-lg font-semibold me-5 text-green-500">Today Order - {todayTicketCount}</h2>
                        <h2 className="text-lg font-semibold text-red-500 mt-2 md:mt-0">Today Canceled Order - {cancelOrder}</h2>
                    </div>
                </div>
                <div className='p-4 max-w-full mx-auto bg-gray-50 ring-1 ring-gray-200 rounded-xl space-y-4'>
                    <div className='flex flex-wrap justify-between '>
                        <div>
                            <h2 className="text-lg font-bold mb-3 md:mb-0">Orders Filters</h2>
                        </div>
                        <div>
                            <button
                                className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300"
                                onClick={handleReset}
                            >
                                Reset
                            </button>
                            <button
                                className="px-4 py-1 mx-3 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300"
                                onClick={() => { setCancelCourier('canceled'); }}
                            >
                                Cancel courier
                            </button>
                            <button
                                className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300"
                                onClick={() => { setCancelCourier('pending'); }}
                            >
                                Pending
                            </button>
                            <button
                                className="px-4 py-1 md:ms-3 mt-3 md:mt-0 ms-3 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300"
                                onClick={() => { setCancelCourier('booked'); }}
                            >
                                Booked
                            </button>
                        </div>

                    </div>

                    <div className='lg:flex space-y-7 lg:space-y-0 lg:justify-between'>
                        <div>
                            <p className="text-sm font-medium text-gray-700">Search</p>
                            <div className='md:flex md:space-x-4 space-x-0'>
                                <div>
                                    <input type="text" onChange={(e) => setSearchUser(e.target.value)} onKeyDown={handleSearch} value={searchUser} placeholder="Search..." className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none p-1.5 border rounded-md" />                                </div>
                            </div>
                        </div>

                        <div className='md:flex md:space-x-4 space-x-0 space-y-3 md:space-y-0'>
                            <div>
                                <p className="text-sm font-medium text-gray-700">From</p>
                                <div className='md:flex md:space-x-4 space-x-0'>
                                    <input
                                        type="date"
                                        className="mt-1 w-full md:w-none p-1.5 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 border rounded-md"
                                        value={fromDate}
                                        onChange={(e) => { fromDateChange(e) }}
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-sm font-medium text-gray-700">To</p>
                                <div className='md:flex md:space-x-4 space-x-0'>
                                    <div>
                                        <input
                                            type="date"
                                            className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none p-1.5 border rounded-md"
                                            value={toDate}
                                            onChange={(e) => toDateChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className='justify-end flex  '>
                        {/* <div className='mt-2' >
                            <label >
                                Items per page:
                                <select value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
                                 
                                    <option value={100}>100</option>
                                    <option value={50}>50</option>
                                </select>
                            </label>
                        </div> */}

                        <div>
                        <CustomPagination
        totalItems={data?.getOrders?.count || 0}
        limit={limit}
        pageNo={page}
        handlePageClick={handlePageClick}
      />
                        </div>
                    </div>
                </div>

                <div className="overflow-x-auto rounded-t-xl  border border-gray-200">
                   
                   <p className='font-semibold m-3'>Total : {data?.getOrders?.count}</p>
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">No.</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Order Date</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">AWB No.</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Customer Name</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Pickup Address</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Delivery Address</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Order Id</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Full Details</td>
                            </tr>
                        </thead>
                        {loading ?
                            <Loader className="mx-auto" />
                            : (
                                <tbody>

                                    {data?.getOrders?.count > 0 && data?.getOrders?.data && data?.getOrders?.data.map((row, index) => (
                                        <tr key={index} className='hover:bg-gray-50'>
                                            <td className="py-2.5 text-sm px-4 border-b">{index + 1 + (page * limit)}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{moment(row?.createdAt).format('DD/MM/YYYY')}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.awb_number}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.pickupAddress?.name}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.pickupAddress?.address1} , {row?.pickupAddress?.city} , {row?.pickupAddress?.stateId?.name} - {row?.pickupAddress?.zipCode}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.deliveryAddress?.address1} , {row?.deliveryAddress?.city} , {row?.deliveryAddress?.stateId?.name} - {row?.deliveryAddress?.zipCode}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.order_id}</td>
                                            <td className="py-2.5 text-sm px-4 border-b text-blue-400 font-semibold"><Link to='/admintrack' state={{ id: row?.id }}> View Details</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                    </table>
                </div>


            </div>
        </div>
    )
}

export default AdminTodayOrder;