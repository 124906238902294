import React, { useEffect } from 'react'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js'
import {IoDiamondOutline,IoIceCreamOutline,IoMagnetOutline} from "react-icons/io5";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { GiPoisonBottle,GiChemicalDrop,GiBatteries,GiPistolGun,GiPlantsAndAnimals} from "react-icons/gi";
import { PiBombLight } from "react-icons/pi";
import { LuPaintBucket,LuFuel } from "react-icons/lu";
import { TbWashMachine } from "react-icons/tb";
import { FaCottonBureau } from "react-icons/fa6";


const RetrictedItem = () => {
    return (
        <div>
            <Header />
            <div className='mx-auto max-w-7xl px-6 lg:px-8 mt-9'>
                <h2 className="text-2xl font-bold text-gray-900 mb-7">Restricted Items</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-9 gap-x-8'>
                    <div className='rounded-lg p-4  ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <IoDiamondOutline className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Jewelry</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Precious stones, ornaments gems and jewelry
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <HiOutlineCurrencyRupee className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Currency</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Uncrossed(bearer cheques) drafts/cheques, currency and coins
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <GiPoisonBottle className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Poison</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Poision, harmful liquids and other similar substances
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <PiBombLight className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Explosives</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Firearms, explosives and military equipment.
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <LuPaintBucket className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Paint</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Oil-based paint, Thinners (flammable liquids) and Industrial solvents
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <GiChemicalDrop className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Chemicals</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Insecticides, Garden chemicals (fertilizers, poisons)
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <TbWashMachine className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Machinery</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Chain saws, outboard engine containing fuel or that have contained fuel
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <LuFuel className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Fuels</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Fuel for camp stoves, lanterns, torches or heating elements
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <FaCottonBureau className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Toxins</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Any compound, liquid or gas that has toxic characteristics
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <GiBatteries className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Batteries</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Automobile batteries, Lithium batteries and others
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <GiPistolGun className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Arms</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Arms, fire arms and ammunitions of any kind
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <IoIceCreamOutline className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Ice</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Dry ice in the forms of carbon dioxide or solid state
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <GiPlantsAndAnimals className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Plants</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Live plants, plant pots, debris and other plant products
                        </p>
                    </div>
                    <div className='rounded-lg p-4 ring-1 ring-slate-200'>
                        <div className="flex items-start">
                            <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                <IoMagnetOutline className="h-6 w-6 text-black" aria-hidden="true" />
                            </div>
                            <p className=" font-semibold self-center ms-3 text-black">Others</p>

                        </div>
                        <p className="mt-3 text-gray-500 text-sm">
                        Magnetized materials, Infectious substances, Flammable adhesives
                        </p>
                    </div>

                </div>
            </div>
            <div className='mt-14'>
            <Footer />
            </div>
        </div>
    )
}

export default RetrictedItem
