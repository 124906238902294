import React from 'react';
import Footer from './Footer.js';
import Header from '../Header/Header.js';
import f1Image from '../asset/img/f1.jpg';

const FraudDisclaimer = () => {
  return (
    <>
      <Header />
      <div className='relative'>
      <img src={f1Image} className='w-full' alt="Privacy Policy" />
        <p className='font-bold text-white text-2xl absolute bottom-6 left-6 z-30 p-4'>
        Fraud Disclaimer
        </p>
      </div>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 py-12 lg:px-8'>
      <p className="text-xl font-bold mb-3">Fraud Disclaimer :</p>
        <p className='mb-7'>At UK Logistic, your safety and security are our top priorities. We are committed to providing a reliable and trustworthy service, but we also want to ensure that our customers are aware of potential fraud risks that could arise during the shipping and delivery process.</p>

        <p className="text-xl font-bold mb-7">Protect Yourself from Fraud :</p>
        <div className='mb-7'>● <strong className='mt-2'>Verify Communication :</strong><p> Our company will never ask for sensitive personal information, such as your password or payment details, through unsolicited calls, emails, or text messages. If you receive suspicious communication claiming to be from UK Logistic, please contact our customer service immediately.</p></div>
        <div className='mb-7'>● <strong className='mt-2'>Check Shipment Details :</strong><p> Always verify shipment details, including tracking numbers and delivery addresses, through our official website or app. Do not rely on third-party sites or links sent through unsolicited messages.</p></div>
        <div className='mb-7'>● <strong className='mt-2'>Be Cautious of Payment Requests :</strong><p> We will never ask for additional payment after a shipment has been dispatched. Any such requests should be considered fraudulent. Report these immediately to our customer support.</p></div>
        <div className='mb-7'>● <strong className='mt-2'>Suspicious Activity :</strong><p> If you notice any suspicious activity related to your shipments or account, such as unauthorized changes to your delivery address or unexpected charges, please notify us right away.</p></div>
        <div className='mb-7'>● <strong className='mt-2'>Protect Your Information :</strong><p> Keep your account information, including login credentials, secure and private. Do not share this information with anyone.</p></div>


        <p className="text-xl font-bold mt-10 mb-4">Be Aware of Scams :</p>
        <p>Scammers may attempt to impersonate our company through fake emails, calls, or messages, claiming to be from our customer service team. Always verify the authenticity of any communication you receive from us. We will never ask for sensitive personal information, such as your bank account details or passwords, via unsolicited calls, emails, or messages.
        </p>

        <p className="text-xl font-bold mt-10 mb-4">  Reporting Fraud :</p>
        <p>If you believe you have been targeted by fraud related to our services, please report the incident to us as soon as possible. We take all reports seriously and will work with the appropriate authorities to investigate and resolve any issues.
        </p>

        <p className="text-xl font-bold mt-10 mb-4">Disclaimer :</p>
        <p>UK Logistic is not liable for any loss or damage resulting from fraudulent activities conducted by third parties. We encourage all customers to remain vigilant and take the necessary precautions to protect themselves from potential fraud.
        </p>
      </div >
      <Footer />
    </>
  );
};

export default FraudDisclaimer;
