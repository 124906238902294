import React from 'react'
import AdminHeader from './AdminHeader.js'
import { Link } from 'react-router-dom'
import { GET_MONTHLY_ORDERS, GET_TOTAL_COUNT_DASHBOARD, GET_TOTAL_COUNT_TICKET, GET_User_DAILY_MONTHLY } from '../graphql/Query/Order.js'
import { useQuery } from '@apollo/client'

const AdminHome = () => {
  const { data } = useQuery(GET_MONTHLY_ORDERS);
  const { data: totalCount } = useQuery(GET_TOTAL_COUNT_DASHBOARD);
  const { data: userCount } = useQuery(GET_User_DAILY_MONTHLY);
  const { data: supportCount } = useQuery(GET_TOTAL_COUNT_TICKET);

  const monthlyCount = userCount?.getUserCounts?.monthlyUser.reduce((acc, item) => acc + item.count, 0);
  const dailyCount = userCount?.getUserCounts?.dailyUser.reduce((acc, item) => acc + item.count, 0);
  const monthlyCountSupport = supportCount?.getSupportCounts?.monthlySupport.reduce((acc, item) => acc + item.count, 0);
  const dailyCountSupport = supportCount?.getSupportCounts?.dailySupport.reduce((acc, item) => acc + item.count, 0);
  const monthlyCountOrder = data?.getOrderCounts?.monthlyOrders.reduce((acc, item) => acc + item.count, 0);
  const dailyCountOrder = data?.getOrderCounts?.dailyOrders.reduce((acc, item) => acc + item.count, 0);
  return (
    <>
      <AdminHeader />
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6  py-10'>
          <Link to="/admintodayorder"> <div className='ring-1 ring-gray-300 hover:bg-gray-100 cursor-pointer p-4 bg-gray-50 space-y-3'>
            <div className='space-y-3'>
              <p className=' font-semibold'>Today Orders</p>
              <div className='space-y-2'>
                <p>Total : {totalCount?.getDashboardCount?.order}</p>
                <p>Monthly : {monthlyCountOrder}</p>
                <p> Daily : {dailyCountOrder}</p>
              </div>
            </div>
            <p className='border border-gray-300'></p>
            <p className='text-gray-400 text-sm'>View Details</p>
          </div> </Link>
          <Link to='/adminticket' state={{ dailyCountSupport: dailyCountSupport }}>   <div className='ring-1 ring-gray-300 hover:bg-gray-100 cursor-pointer p-4 bg-gray-50 space-y-3'>
            <div className='space-y-3'>
              <p className=' font-semibold'>Today Tickets</p>
              <div className='space-y-2'>
                <p>Total :{totalCount?.getDashboardCount?.ticket}</p>
                <p>Monthly : {monthlyCountSupport}</p>
                <p>Daily : {dailyCountSupport}</p>
              </div>
            </div>
            <p className='border border-gray-300'></p>
            <p className='text-gray-400 text-sm'>View Details</p>
          </div></Link>

          <Link to='/admintotalcustomer'> <div className='ring-1 ring-gray-300 hover:bg-gray-100 cursor-pointer p-4 bg-gray-50 space-y-3'>
            <div className='space-y-3'>
              <p className=' font-semibold text-grey-700'>Customers</p>
              <div className='space-y-2'>
                <p>Total : {totalCount?.getDashboardCount?.customer}</p>
                <p>Monthly : {monthlyCount}</p>
                <p>Daily : {dailyCount}</p>
              </div>
            </div>
            <p className='border border-gray-300'></p>
            <p className='text-gray-400 text-sm'>View Details</p>
          </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default AdminHome
