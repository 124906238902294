import moment from 'moment';
import React from 'react';
import playImagei from '../asset/img/logo1.png';

const Invoice = ({ order }) => (
  <div id="invoice" className="">
    <div className=" bg-white p-9">
      <div className="flex justify-between items-center border-b pb-6 mb-6">
        <div>
          <div className="flex">
            <img src={playImagei} className="h-8 w-8 mt-1.5" alt="UK Logistic" />
            <span className="text-xl font-bold ms-2">UK Logistic</span>
          </div>
          <h2 className="text-xl font-semibold mt-4">Invoice</h2>
          <p className="text-sm text-gray-800">AWB No: {order?.awb_number}</p>
          <p className="text-sm text-gray-800">Order No: {order?.order_id}</p>
          <p className="text-sm text-gray-800">Order Date: {moment(order?.createdAt).format('DD/MM/YYYY')}</p>
        </div>
        <div className="text-right">
          <h2 className="text-xl font-semibold text-green-600">PAID</h2>
          <h2 className="text-lg font-semibold text-gray-800">SD</h2>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-800">Billing Information:</h2>
        <p className="text-sm text-gray-800">{order?.deliveryAddress?.name}</p>
        <p className="text-sm text-gray-800">{order?.deliveryAddress?.address1}, {order?.deliveryAddress?.city}, {order?.deliveryAddress?.stateId?.name} - {order?.deliveryAddress?.zipCode}</p>
        <p className="text-sm text-gray-800">Mobile No: {order?.deliveryAddress?.phone}</p>
      </div>

      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-800">Courier Information:</h2>
        <p className="text-sm text-gray-800">Fwd Destination Code: {order?.pickupAddress?.city}/SRT</p>
        <p className="text-sm text-gray-800">Courier Name: UK Logistic {order?.package_weight_Length}</p>
      </div>

      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-800">Product Details:</h2>
        <table className="w-full mt-4 text-left border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="border px-4 py-2 text-gray-800">AWB NO.</th>
              <th className="border px-4 py-2 text-gray-800">Item Name</th>
              <th className="border px-4 py-2 text-gray-800">Package Value</th>
              <th className="border px-4 py-2 text-gray-800">Qty.</th>
              <th className="border px-4 py-2 text-gray-800">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2 text-gray-800">{order?.awb_number}</td>
              <td className="border px-4 py-2 text-gray-800">{order?.ProductName}</td>
              <td className="border px-4 py-2 text-gray-800">₹{order?.ProductPrice}</td>
              <td className="border px-4 py-2 text-gray-800">1</td>
              <td className="border px-4 py-2 text-gray-800">₹{order?.order_amount}</td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-end mt-4">
          <div className="w-1/2 text-right">
            <p className="text-lg font-bold text-gray-800">Order Total: ₹{order?.order_amount}</p>
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-lg font-semibold text-gray-800">Pickup and Return Address:</h2>
        <p className="text-sm text-gray-800">{order?.pickupAddress.name}</p>
        <p className="text-sm text-gray-800">{order?.pickupAddress.address1}, {order?.pickupAddress.city}, {order?.pickupAddress.stateId.name}, {order?.pickupAddress.zipCode}</p>
        <p className="text-sm text-gray-800">Mobile No.: {order?.pickupAddress.phone}</p>
      </div>
    </div>
  </div>


);

export default Invoice;
