import React, { useEffect, useState } from 'react';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';

const Orders = () => {
    const [activeButton, setActiveButton] = useState('All');

    const renderContent = () => {
        switch (activeButton) {
            case 'All':
                return (
                    <div className=' mx-auto max-w-7xl px-6 lg:px-8'>
                        <p>This is the content for All.</p>
                        <p>This is different content for From me.</p>
                        <h1>hello</h1>
                    </div>
                )
            case 'To me':
                return (
                    <div className=' mx-auto max-w-7xl px-6 lg:px-8'>
                        <p>Hey, come to this page. This is the content for To me.</p>
                        <p>This is different content for From me.</p>
                        <h1>hello</h1>
                    </div>
                )
            case 'From me':
                return (
                    <div className=' mx-auto max-w-7xl px-6 lg:px-8'>
                        <p>This is different content for From me.</p>
                        <h1>hello</h1>
                    </div>
                )
            default:
                return null;
        }
    };
    return (
        <div>
            <Header />
            <div className="flex">
                <button
                    className={`px-4 py-2 rounded ${activeButton === 'All' ? 'bg-yellow-500 text-black' : 'bg-blue-500 text-white'}`}
                    onClick={() => setActiveButton('All')}
                >
                    All
                </button>
                <button
                    className={`mx-4 px-4 py-2 rounded ${activeButton === 'To me' ? 'bg-yellow-500 text-black' : 'bg-blue-500 text-white'}`}
                    onClick={() => setActiveButton('To me')}
                >
                    To me
                </button>
                <button
                    className={`px-4 py-2 rounded ${activeButton === 'From me' ? 'bg-yellow-500 text-black' : 'bg-blue-500 text-white'}`}
                    onClick={() => setActiveButton('From me')}
                >
                    From me
                </button>
            </div>
            <div className="mt-4">
                {renderContent()}
            </div>
            <Footer />
        </div>
    );
};

export default Orders;
