import React, { useState } from 'react'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js'
import planeImage from '../asset/img/plane.webp';
import s1 from '../asset/img/sd1.png';
import s2 from '../asset/img/sd2.png';
import sd3 from '../asset/img/sd3.png';
import sd4 from '../asset/img/sd4.png';
import sd5 from '../asset/img/sd5.png';
import sd6 from '../asset/img/sd6.png';
import sd7 from '../asset/img/sd7.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FaRegEdit } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { SlLocationPin, SlEnvolope } from "react-icons/sl";
import { GoCircle } from "react-icons/go";
import Select from 'react-select';
import AddressModal from './AddressModel.js';
import AddressDetailsModel from './AddressDetailsModel.js';
import DeliveryDetailsModel from './DeliveryDetailsModel.js';
import DeliveryModal from './DeliveryModal.js';
import PaymentTotalModel from './PaymentTotalModel.js';
import { LuPlaneTakeoff } from "react-icons/lu";

const ForeignAir = () => {
    const [showModal, setShowModal] = useState(false);
    const [showModalAddress, setShowModalAddress] = useState(false);

    const [deliveryshowModal, setdeliveryShowModal] = useState(false);
    const [deliveryModal, setdeliveryModal] = useState(false);

    const [paymentShowModel, setPaymentShowModel] = useState(false);
    return (
        // <div>
        //     <Header />
            


        //     {/* <AddressModal data={getReceiverData} refetch={reciverRefetch} show={showModal} onClose={() => setShowModal(false)} setShowModal={setShowModal} setShowModalAddress={setShowModalAddress} setSelectedPickupAddress={setSelectedPickupAddress} />
        //     <AddressDetailsModel refetch={reciverRefetch} show={showModalAddress} onClose={handleCloseAddressDetails} setShowModalAddress={setShowModalAddress} setShowModal={setShowModal} setPickupAddress={setPickupAddress} pickupAddress={pickupAddress} setSelectedPickupAddress={setSelectedPickupAddress} />
        //     <DeliveryDetailsModel refetch={refetch} show={deliveryModal} onClose={handleCloseDeliveryDetails} setdeliveryModal={setdeliveryModal} setdeliveryShowModal={setdeliveryShowModal} setDeliveryAddress={setDeliveryAddress} deliveryAddress={deliveryAddress} setSelectedDeliveryAddress={setSelectedDeliveryAddress} />
        //     <DeliveryModal refetch={refetch} show={deliveryshowModal} data={getDeliveryData} onClose={() => setdeliveryShowModal(false)} setdeliveryShowModal={setdeliveryShowModal} setdeliveryModal={setdeliveryModal} setSelectedDeliveryAddress={setSelectedDeliveryAddress} />
        //     <PaymentTotalModel data={data} paymentSessionId={paymentSessionId} show={paymentShowModel} onClose={() => setPaymentShowModel(false)} setPaymentShowModel={setPaymentShowModel} setHandlePayModel={setHandlePayModel} /> */}
        //     <AddressModal show={showModal} onClose={() => setShowModal(false)} setShowModal={setShowModal} setShowModalAddress={setShowModalAddress} />
        //     <AddressDetailsModel show={showModalAddress} setShowModalAddress={setShowModalAddress} setShowModal={setShowModal} />
        //     <DeliveryDetailsModel show={deliveryModal} setdeliveryModal={setdeliveryModal} setdeliveryShowModal={setdeliveryShowModal}/>
        //     <DeliveryModal show={deliveryshowModal} onClose={() => setdeliveryShowModal(false)} setdeliveryShowModal={setdeliveryShowModal} setdeliveryModal={setdeliveryModal} />
        //     <PaymentTotalModel show={paymentShowModel} onClose={() => setPaymentShowModel(false)} setPaymentShowModel={setPaymentShowModel} />
        //     <Footer />
        // </div>



        <div>
            <Header />
            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-24">
                <img
                    alt=""
                    src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                />
                <div
                    aria-hidden="true"
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                >
                    <div
                        style={{
                            clipPath:
                                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                        }}
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    />
                </div>
                <div
                    aria-hidden="true"
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                >
                    <div
                        style={{
                            clipPath:
                                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                        }}
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="bg-white rounded-lg shadow-md p-6 md:flex md:items-center md:space-x-6 max-w-4xl mx-auto">
                        <div className="flex justify-center md:justify-start mb-4 md:mb-0">
                        <img src={planeImage} className="rounded-xl hover:opacity-80" alt="Plane" />
                        </div>
                        <div>
                            <h2 className="text-sm font-semibold mb-1 text-gray-800">EXCITING TIMES AHEAD</h2>
                            <h2 className="text-xl font-bold mb-4 text-gray-900">Coming Soon...</h2>
                            <h2 className="text-lg font-semibold mb-1 text-gray-900">International Courier</h2>
                            <div className="text-sm flex items-center">
                                <span className="bg-gray-200 px-4 py-0.5 rounded-lg mr-2">
                                    Maximum Weight
                                </span>
                            </div>
                            <p className="mt-4 text-lg font-semibold text-gray-900">
                                Unified Tracking Experience
                            </p>
                            <p className="text-sm text-gray-500 mt-2">
                                Ship your international orders to over 220+ countries and territories with India’s leading cross border Courier solution.
                                Our international shipping platform makes it simpler to serve your international customers and make it big in the global arena.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForeignAir
