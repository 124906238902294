import React, { useEffect, useState } from 'react';
import { INSERT_COMMITION, ORDER_COMMITION } from '../graphql/Mutation/OrderCommition.js';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import AdminHeader from './AdminHeader.js';


const AdminComision = () => {
    const [commition, setCommition] = useState();
    const { data: orderCommision, refetch: orderREfetch } = useQuery(ORDER_COMMITION);
    const [inputCommition] = useMutation(INSERT_COMMITION);
    const handleInsertCommition = () => {
        inputCommition({
            variables: {
                orderPercentage: parseFloat(commition),
            },
        })
            .then(response => {
                if (response.data.upsertCommition) {
                    setCommition(null);
                    orderREfetch();
                    toast.success("Order Commition Added Sucessfully");
                }
            })
            .catch(err => {
                toast.error(err);
            });
    };
    return (
        <>
            <AdminHeader />
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 my-10">
                <div className="mb-3">
                    <p className="font-bold mb-3 text-2xl">
                        {orderCommision?.getCommitionAmount?.order_percentage}%
                    </p>
                    <p className="font-medium text-xl">
                        Commition : {orderCommision?.getCommitionAmount?.order_percentage}%
                    </p>
                </div>
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="mb-4 md:mb-0 w-full md:w-auto">
                        <input
                            type="text"
                            onChange={(e) => setCommition(e.target.value)}
                            placeholder='Enter Comission'
                            className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full p-2 border border-gray-700 rounded-md"
                        />
                    </div>
                    <button
                        className="py-1 px-4 bg-black text-white font-semibold rounded-md w-full md:w-auto"
                        onClick={handleInsertCommition}
                    >
                        Submit
                    </button>
                </div>

                <div className="overflow-x-auto rounded-t-xl mt-14 border border-gray-200">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <td className="py-4 font-semibold bg-gray-50  px-4 border-b">No.</td>
                                <td className="py-4 font-semibold bg-gray-50  px-4 border-b">Start Date</td>
                                <td className="py-4 font-semibold bg-gray-50  px-4 border-b">End Date</td>
                                <td className="py-4 font-semibold bg-gray-50  px-4 border-b">Comission %</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </>
    )
}

export default AdminComision
