import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import {
    Dialog,
    DialogPanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    Transition,
    TransitionChild, DialogTitle,
} from '@headlessui/react'
import { IoClose } from "react-icons/io5";
import { HiOutlineMenu } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { GrLocation } from "react-icons/gr";
import { PiBoxArrowUp } from "react-icons/pi";
import { ApolloConsumer, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { handleLogin } from '../Common/TokenLogin.js';
import SpinLoader from '../components/SpinLoader.js';
import { GET_ADVERTISE } from '../graphql/Query/Advertise.js';
import { GET_TOPBAR } from '../graphql/Query/TopBar.js';
import { useModal } from '../Contex.js';
import couriernowImage from '../asset/img/couriernow.jpg';
import caldadImage from '../asset/img/caldad.jpg';
import labelguideImage from '../asset/img/labelguide.jpg';
import banffImage from '../asset/img/banff.jpg';
import play1Image from '../asset/img/play1.png';
import applefImage from '../asset/img/applef.png';
import logo1Image from '../asset/img/logo1.png';

const navigation = {
    categories: [
        {
            id: 'Couriers',
            name: 'Couriers',
            featured: [
                {
                    name: 'Ship Now',
                    href: '/documentshipping',
                    imageSrc: couriernowImage,
                    imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
                },
                {
                    name: 'Ship Calculation',
                    href: '/calculatorcourier',
                    imageSrc: caldadImage,
                    imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
                },
            ],
            sections: [
                {
                    id: 'Ship now',
                    name: 'Ship now  ',
                    items: [
                        { name: 'Document ', href: '/documentshipping' },
                        { name: '0 to 30Kg', href: '/documentshipping' },
                        { name: 'Same Day', href: '/sameday' },
                        { name: 'Foreign Air', href: '/foreignair' },
                    ],
                },
                {
                    id: 'Ship Calculation ',
                    name: 'Ship Calculation ',
                    items: [
                        { name: 'Document', href: '/calculatorcourier' },
                        { name: '0 to 30Kg', href: '/calculatorcourier' },
                        { name: 'Same day', href: '/sameday' },
                        { name: 'Foreign Air', href: '/foreignair' },
                    ],
                },
                {
                    id: 'Couriers',
                    name: 'Couriers',
                    items: [
                        { name: 'All Courier', href: '/allOrders', state: { data: 'All Courier' } },
                        { name: 'To me', href: '/allOrders', state: { data: 'To me' } },
                        { name: 'From me', href: '/allOrders', state: { data: 'From me' } },
                        { name: 'Find Courier', href: '/trackcourier' },
                    ],
                },
            ],
        },
        {
            id: 'Account & Lists',
            name: 'Account & Lists',
            featured: [
                {
                    name: 'Label and Packaging Guidelines',
                    href: '/packguidlines',
                    imageSrc: labelguideImage,
                    imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
                },
                {
                    name: 'Restricted Items',
                    href: '/retrictedItem',
                    imageSrc: banffImage,
                    imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
                },
            ],
            sections: [
                {
                    id: 'Account & Lists',
                    name: 'Account & Lists',
                    items: [
                        { name: 'Help', href: '/accordionhelp' },
                        { name: 'Packaging Guidelines', href: '/packguidlines' },
                        { name: 'Restricted Items', href: '/retrictedItem' },
                    ],
                },
                {
                    id: 'My Activities',
                    name: 'My Activities',
                    items: [
                        { name: 'Shipping Label', href: '/shippinglabel' },
                        { name: 'Enquiry Now', icon: 'GrLocation', href: '/anotherhelp' },
                        { name: 'Edit Profile' },
                    ],
                },
                {
                    id: 'Policies',
                    name: 'Policies',
                    items: [
                        { name: 'Privacy Policy', href: '/privacyPolicy' },
                        { name: 'Terms & Conditions', href: '/termsAndConditions' },
                        { name: 'Cookie Policy', href: '/cookiespolicy' },
                        { name: 'Fraud Disclaimer', href: '/fraudDisclaimer' },
                    ],
                },
            ],
        },
    ],
    pages: [
        { name: 'Notification', href: '/notification' },
        { name: 'Support', href: '/accordionhelp' },
        { name: 'Tracking ', href: '/trackcourier' },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Header = () => {
    const { openModal, setModalOpen } = useModal();
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [countNotification, setCountNotification] = useState();
    const token = localStorage.getItem('token');
    const signOut = (client) => {
        localStorage.clear()
        sessionStorage.clear();
        client.resetStore();
        toast.success("user logged out successfully!", {
            autoClose: 2000
        })
    };

    const { data } = useQuery(GET_TOPBAR);
    const [openPincode, setOpenPincode] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object({
        origin: Yup.string().required('Pincode is required')
            .matches(/^[0-9]{6}$/, 'Pincode must be exactly 6 digits'),
        destination: Yup.string().required('Pincode is required')
            .matches(/^[0-9]{6}$/, 'Pincode must be exactly 6 digits'),
    });

    const initialValues = {
        origin: '',
        destination: '',
        payment_type: 'prepaid',
    };

    const onSubmit = async (values) => {
        setIsLoading(true);
        try {
            const authToken = await handleLogin();
            const response = await fetch('https://shipment.xpressbees.com/api/courier/serviceability', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();

            if (!data?.status) {
                setMessage(data?.data);
            } else {
                setMessage("Pincode Service Available")
            }
        } catch (error) {
            toast.error(error?.message)
        } finally {
            setIsLoading(false);
        }
    };
    const closeEventData = () => {
        setOpenPincode(false);
        setMessage('');
    }
    const { data: getAdvertise } = useQuery(GET_ADVERTISE, {
        variables: {
            page: page + 1,
            limit,
            sort: {
                key: "createdAt",
                type: -1
            },
        }
    });
    useEffect(() => {
        const unreadNotificationsCount = getAdvertise?.getBlogs?.data.filter(blog => !blog?.readStatus).length;
        setCountNotification(unreadNotificationsCount);
    }, [getAdvertise]);
    return (
        <div className="bg-white">
            <Transition show={open}>
                <Dialog className="relative z-40 lg:hidden" onClose={setOpen}>
                    <TransitionChild
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </TransitionChild>

                    <div className="fixed inset-0 z-40 flex">
                        <TransitionChild
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <DialogPanel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                                <div className="flex px-4 pb-2 pt-5">
                                    <button
                                        type="button"
                                        className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Close menu</span>
                                        <IoClose className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                {/* Links */}
                                <TabGroup className="mt-2">
                                    <div className="border-b border-gray-200">
                                        <TabList className="-mb-px flex space-x-8 px-4">
                                            {navigation.categories.map((category) => (
                                                <Tab
                                                    key={category.name}
                                                    className={({ selected }) =>
                                                        classNames(
                                                            selected ? 'border-black text-black' : 'border-transparent text-gray-900',
                                                            'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium'
                                                        )
                                                    }
                                                >
                                                    {category.name}
                                                </Tab>
                                            ))}
                                        </TabList>
                                    </div>
                                    <TabPanels as={Fragment}>
                                        {navigation.categories.map((category) => (
                                            <TabPanel key={category.name} className="space-y-10 px-4 pb-8 pt-10">
                                                <div className="grid grid-cols-2 gap-x-4">
                                                    {category.featured.map((item) => (
                                                        <div key={item.name} className="group relative text-sm">
                                                            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                                                <img src={item.imageSrc} alt={item.imageAlt} className="object-cover object-center" />
                                                            </div>
                                                            <Link to={item.href} className="mt-6 block font-medium text-start text-gray-900">
                                                                <span className="absolute inset-0 z-10" aria-hidden="true" />
                                                                {item.name}
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                                {category.sections.map((section) => (
                                                    <div key={section.name}>
                                                        <p id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
                                                            {section.name}
                                                        </p>
                                                        <ul
                                                            role="list"
                                                            aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                                                            className="mt-6 flex flex-col space-y-6"
                                                        >
                                                            {section.items.map((item) => (
                                                                <li key={item.name} className="flow-root">
                                                                    <Link to={item.href} state={item.state} className="-m-2 block p-2 text-gray-500" onClick={(e) => {
                                                                        if (item?.name === 'Edit Profile') {
                                                                            e.preventDefault(); // Prevents the default link behavior
                                                                            setModalOpen(true); // Opens the modal
                                                                        }
                                                                        setOpen(false);
                                                                    }}>
                                                                        {item.name}
                                                                    </Link>
                                                                </li>

                                                            ))}
                                                        </ul>
                                                    </div>

                                                ))}

                                                <img src={play1Image} alt="" className='h-14 cursor-pointer' />
                                                <img src={applefImage} className='h-14 cursor-pointer' alt="" />

                                            </TabPanel>

                                        ))}

                                    </TabPanels>
                                </TabGroup>


                                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                                    {navigation.pages.map((pageData) => (
                                        <div key={pageData.name} className="flow-root">
                                            <Link to={pageData.href}   {...(pageData.name === 'Notification' && {
                                            })} className="-m-2 block p-2 font-medium text-gray-900">
                                                {pageData.name}
                                                {pageData.name === 'Notification' && countNotification > 0 && (
                                                    <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                                                        {countNotification}
                                                    </span>
                                                )}
                                            </Link>
                                        </div>
                                    ))}
                                    <div className=" lg:ml-8 mt-6 lg:flex cursor-pointer" onClick={() => { setOpenPincode(true); setOpen(false); }}>
                                        <div className="flex items-center text-base font-medium text-gray-900">
                                            <GrLocation />
                                            <span className="ml-1 block">Check Pincode</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-t border-gray-200 px-4 py-6">
                                    {!token && <div>
                                        <Link to="/signup" className=" text-base font-medium text-gray-900">
                                            <p>
                                                Sign in
                                            </p>
                                        </Link>
                                        <Link to="/signup" className=" text-base font-medium text-gray-900">
                                            <p className='my-6'>
                                                Create account
                                            </p>
                                        </Link>
                                    </div>}
                                    {token &&
                                        <div className='' >
                                            <div className="flow-root">
                                                <ApolloConsumer>
                                                    {client => (
                                                        <Link to="/signup" onClick={() => signOut(client)} className="  text-base font-medium text-gray-900">
                                                            Log out
                                                        </Link>
                                                    )}
                                                </ApolloConsumer>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </Dialog>
            </Transition>
            <header className="relative bg-white z-10">
                {data?.getTopBar?.content &&
                    <p className="flex h-16 md:h-10 items-center justify-center bg-slate-900 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                        {data?.getTopBar?.content}
                    </p>
                }

                <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-end">
                    <div className="border-b border-gray-200">
                        <div className="flex h-16 items-center justify-between">
                            <button
                                type="button"
                                className="relative rounded-md bg-white p-2 text-gray-400 lg:hidden"
                                onClick={() => setOpen(true)}
                            >
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Open menu</span>
                                <HiOutlineMenu className="h-6 w-6 self-center" aria-hidden="true" />
                            </button>

                            {/* Logo */}
                            <Link to='/'>
                                <div className="ml-4 flex lg:ml-0">
                                    <img src={logo1Image} className="h-8 w-auto" alt="Logo" />
                                    <span className="self-center text-xl font-bold ms-2 ">UK</span>
                                </div>
                            </Link>

                            <PopoverGroup className="hidden lg:ml-8 lg:block lg:self-stretch">
                                <div className="flex h-full space-x-5 xl:space-x-8">
                                    {navigation.categories.map((category) => (
                                        <Popover key={category.name} className="flex">
                                            {({ open }) => (
                                                <>
                                                    <div className="relative flex">
                                                        <PopoverButton
                                                            className={classNames(
                                                                open
                                                                    ? 'border-black text-black'
                                                                    : 'border-transparent text-gray-700 hover:text-gray-800',
                                                                'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out'
                                                            )}
                                                        >
                                                            {category.name}
                                                        </PopoverButton>
                                                    </div>

                                                    <Transition
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="opacity-0"
                                                        enterTo="opacity-100"
                                                        leave="transition ease-in duration-150"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <PopoverPanel className="absolute inset-x-0 top-full text-sm text-gray-500">
                                                            <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />
                                                            <div className="relative bg-white">
                                                                <div className="mx-auto max-w-7xl px-8 bg-white">
                                                                    <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16 bg-white">
                                                                        <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                                                            {category.featured.map((item) => (
                                                                                <div key={item.name} className="group relative text-base sm:text-sm">
                                                                                    <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                                                                        <img
                                                                                            src={item.imageSrc}
                                                                                            alt={item.imageAlt}
                                                                                            className="object-cover object-center"
                                                                                        />
                                                                                    </div>
                                                                                    <Link to={item.href} className="mt-6 block text-start font-medium text-gray-900">
                                                                                        <span className="absolute inset-0 z-10" aria-hidden="true" />
                                                                                        {item.name}
                                                                                    </Link>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className="row-start-1 grid grid-cols-3 gap-x-8 gap-y-10 text-sm">
                                                                            {category.sections.map((section) => (
                                                                                <div key={section.name}>
                                                                                    <p id={`${section.name}-heading`} className="font-medium text-start text-gray-900">
                                                                                        {section.name}
                                                                                    </p>
                                                                                    <ul
                                                                                        role="list"
                                                                                        aria-labelledby={`${section.name}-heading`}
                                                                                        className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                                                    >
                                                                                        {section.items.map((item) => (
                                                                                            <li key={item.name} className="flex">
                                                                                                <Link to={item.href} state={item.state} className="hover:text-gray-800" onClick={(e) => {
                                                                                                    if (item?.name === 'Edit Profile') {
                                                                                                        e.preventDefault();
                                                                                                        setModalOpen(true);
                                                                                                    }
                                                                                                    setOpen(false);
                                                                                                }}>
                                                                                                    {item.name}
                                                                                                </Link>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            ))}
                                                                            <img src={play1Image} alt="" className='cursor-pointer' />
                                                                            <img src={applefImage} className='h-13 cursor-pointer' alt="" />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </PopoverPanel>
                                                    </Transition>
                                                </>
                                            )}
                                        </Popover>
                                    ))}

                                    {navigation.pages.map((pageData) => (
                                        <Link
                                            key={pageData.name}
                                            to={pageData.href}
                                            {...(pageData.name === 'Notification' && {
                                            })}
                                            className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                                        >
                                            {pageData.name}
                                            {pageData.name === 'Notification' && countNotification > 0 && (
                                                <span className="ml-1 inline-flex items-center justify-center px-1.5 py-1 text-xs font-bold leading-none text-white bg-slate-800 rounded-full">
                                                    {countNotification}
                                                </span>
                                            )}
                                        </Link>
                                    ))}
                                </div>
                            </PopoverGroup>

                            <div className="lg:ml-9 xl:ml-auto ml-auto flex items-center">
                                {!token && <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                                    <Link to="/signup" className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                        Sign in
                                    </Link>
                                    <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                                    <Link to="/signup" className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                        Create account
                                    </Link>
                                </div>}
                                {token &&
                                    <div className='hidden md:block' >
                                        <div className="flow-root">
                                            <ApolloConsumer>
                                                {client => (
                                                    <Link to="/signup" onClick={() => signOut(client)} className=" block text-sm font-medium text-gray-700 group-hover:text-gray-800">
                                                        Log out
                                                    </Link>
                                                )}
                                            </ApolloConsumer>
                                        </div>
                                    </div>
                                }
                                <div className="hidden lg:ml-8 lg:flex cursor-pointer" onClick={() => setOpenPincode(true)}>
                                    <div className="flex items-center text-gray-700 hover:text-gray-800">
                                        <GrLocation />
                                        <span className="ml-1 block text-sm font-medium">Check Pincode</span>
                                    </div>
                                </div>
                                <div className="ml-4 flow-root lg:ml-6">
                                    <Link to="/allOrders" state={{ data: 'All Courier' }} className="group -m-2 flex items-center p-2">
                                        <PiBoxArrowUp
                                            className="h-7 w-7 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-1 text-sm font-medium text-gray-700 group-hover:text-gray-800">All Courier</span>
                                        <span className="sr-only">items in cart, view bag</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <Dialog open={openPincode} onClose={closeEventData} className="relative z-10">
                <div
                    transition
                    className="fixed inset-0 bg-black bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                >
                </div>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform w-full overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <p className='font-bold text-xl mb-5'>Available Pincode</p>
                                {message && message === 'Pincode Service Available' ? <p className=" text-green-500 mb-4 font-semibold">{message}</p> : <p className=" text-red-500 font-semibold mb-4">{message}</p>}
                                <div className="">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, { setSubmitting }) => {
                                            onSubmit(values);
                                            setSubmitting(false);
                                        }}
                                    >
                                        {({ isSubmitting, setFieldValue }) => (
                                            <Form className="space-y-4">
                                                <div>
                                                    <label htmlFor="origin" className="block text-sm font-medium text-gray-700">
                                                        Pickup Pincode <span className="text-red-500">*</span>
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        id="origin"
                                                        name="origin"
                                                        placeholder="Enter pickup pincode"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            setFieldValue('origin', value);
                                                        }}
                                                        className="mt-2 block w-full px-3 py-2 border focus:outline-none focus:ring-slate-600 focus:border-slate-600 border-slate-400 rounded-md"
                                                    />
                                                    <ErrorMessage name="origin" component="div" className="text-red-500 text-sm" />
                                                </div>

                                                <div>
                                                    <label htmlFor="destination" className="block text-sm font-medium text-gray-700">
                                                        Delivery Pincode <span className="text-red-500">*</span>
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        id="destination"
                                                        name="destination"
                                                        placeholder="Enter delivery pincode"
                                                        className="mt-2 block w-full px-3 py-2 border focus:outline-none focus:ring-slate-600 focus:border-slate-600 border-slate-400 rounded-md"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            setFieldValue('destination', value);
                                                        }}
                                                    />
                                                    <ErrorMessage name="destination" component="div" className="text-red-500 text-sm" />
                                                </div>
                                                <div className="flex justify-end">
                                                    <button
                                                        type="submit"
                                                        className="px-4 py-2 mt-3 bg-slate-900 w-full font-medium text-white rounded-lg flex justify-center items-center"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <div className='flex justify-center items-center'>
                                                                <span className='mr-2'>Loading...</span>
                                                                <SpinLoader />
                                                            </div>
                                                        ) : (
                                                            'Check Pincode'
                                                        )}
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6">
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => { setOpenPincode(false); setMessage('') }}
                                    className="mt-3 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default Header
