import React, { useState, useEffect } from 'react';
import AdminHeader from './AdminHeader.js';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useMutation, useQuery } from '@apollo/client';
import { GET_GENERAL_SETTING, INSERT_UPDATE_GENERALSETTING } from '../graphql/Mutation/GeneralSetting.js';
import { toast } from 'react-toastify';

const AdminPolicy = () => {
    const [generalData, setGeneralData] = useState({
        id: '',
        prohibitedList: ''
    });

    const { data, loading, error } = useQuery(GET_GENERAL_SETTING);
    const [generalSettings] = useMutation(INSERT_UPDATE_GENERALSETTING);

    useEffect(() => {
        if (data && data?.getGeneralSettings) {
            setGeneralData({
                id: data?.getGeneralSettings?.id || '',
                prohibitedList: data?.getGeneralSettings?.prohibitedList || '',
            });
        }
    }, [data]);


    const changeHandleProhibitedList = (e, editor) => {
        setGeneralData({
            ...generalData,
            prohibitedList: editor.getData()
        });
    };

    const fromOnSubmitGeneralSettings = (event) => {
        event.preventDefault();
        const input = { ...generalData };
        if (generalData?.id) {
            input.id = generalData?.id;
        }

        generalSettings({ variables: { input } }).then(({ data }) => {
            if (data?.insertUpdateGeneralSettings) {
                toast.success(`General settings ${generalData.id ? 'updated' : 'inserted'} successfully!`, {
                    autoClose: 2000
                });
            }
        }).catch((err) => {
            toast.error(err.message, {
                autoClose: 2000
            });
        });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading general settings.</p>;

    return (
        <>
            <AdminHeader />
            <div className="mx-auto max-w-7xl px-6 lg:px-8 my-10">
                <form className="needs-validation" noValidate onSubmit={fromOnSubmitGeneralSettings}>
                    

                    <div className="ibox-title rounded-t mt-10 mb-2 font-semibold">
                        <span>Prohibited List <span className="text-red-500">*</span></span>
                    </div>
                    <div className="ibox-content rounded-b">
                        <CKEditor
                            editor={ClassicEditor}
                            onChange={changeHandleProhibitedList}
                            data={generalData?.prohibitedList}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary mt-6 w-full text-white bg-slate-700 p-2 rounded-lg hover:bg-gray-900 transition">
                        Save
                    </button>
                </form>
            </div>
        </>
    );
};

export default AdminPolicy;
