import React from 'react'
import Header from '../Header/Header.js'
import Footer from './Footer.js'
import f3Image from '../asset/img/f3.jpg';

const Cookies = () => {
    return (
        <div>
            <Header />
            <div className='relative'>
            <img src={f3Image} className='w-full' alt="Privacy Policy" />
                <p className='font-bold text-white text-2xl absolute bottom-6 left-6 z-30 p-4'>
                    Cookies Policy
                </p>
            </div>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 py-12 lg:px-8'>
                <p className='text-2xl font-bold'>What are cookies?</p>
                <p className='font-normal mt-4 mb-7'>Cookies are small text files that enable us to store information related to your search while you visit our website. Cookies help us to determine how frequently our internet pages are accessed. For more information, please see the Wikipedia article on HTTP Cookies.</p>

                <p className='text-xl font-bold'>Why and how we use cookies?</p>
                <p className='font-normal mt-4'>We use the below listed cookies to collect information online. Also defined is the purpose and usage of the data collected.</p>

                <div className="overflow-x-auto my-10">
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead className="bg-gray-900 text-white">
                            <tr>
                                <th className="py-2 px-4 text-left text-sm font-semibold">Cookie</th>
                                <th className="py-2 px-4 text-left text-sm font-semibold">Purpose & Usage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-t border-gray-300">
                                <td className="py-2 px-4 text-sm font-medium text-gray-900">_fbp</td>
                                <td className="py-2 px-4 text-sm text-gray-700">
                                    Used by Facebook to deliver a series of advertisement products such as real-time bidding from third-party
                                    advertisers. The main purpose of this cookie is <span className="font-semibold">Targeting/Advertising</span>
                                </td>
                            </tr>
                            <tr className="border-t border-gray-300">
                                <td className="py-2 px-4 text-sm font-medium text-gray-900">_ga</td>
                                <td className="py-2 px-4 text-sm text-gray-700">
                                    This cookie name is associated with Google Universal Analytics - which is a significant update to Google's
                                    more commonly used analytics service. This cookie is used to distinguish unique users by assigning a
                                    randomly generated number as a client identifier. It is included in each page request in a site and used
                                    to calculate visitor, session and campaign data for the site's analytics reports. By default, it is set to
                                    expire after 2 years, although this is customizable by website owners. The main purpose of this cookie is{' '}
                                    <span className="font-semibold">Performance</span>
                                </td>
                            </tr>
                            <tr className="border-t border-gray-300">
                                <td className="py-2 px-4 text-sm font-medium text-gray-900">_gat</td>
                                <td className="py-2 px-4 text-sm text-gray-700">
                                    This cookie name is associated with Google Universal Analytics. This cookie is used to limit requests to
                                    Google Analytics. Expires after one minute. The main purpose of this cookie is{' '}
                                    <span className="font-semibold">Performance</span>
                                </td>
                            </tr>
                            <tr className="border-t border-gray-300">
                                <td className="py-2 px-4 text-sm font-medium text-gray-900">_gcl_au</td>
                                <td className="py-2 px-4 text-sm text-gray-700">
                                    Used by Google AdSense for experimenting with advertisement efficiency across websites using their
                                    services. The main purpose of this cookie is{' '}
                                    <span className="font-semibold">Targeting/Advertising</span>
                                </td>
                            </tr>
                            <tr className="border-t border-gray-300">
                                <td className="py-2 px-4 text-sm font-medium text-gray-900">_gid</td>
                                <td className="py-2 px-4 text-sm text-gray-700">
                                    This cookie name is associated with Google Universal Analytics. This cookie is used to distinguish
                                    different users by a non-personal random number, instead of using personal information. Expires after 24
                                    hours. The main purpose of this cookie is <span className="font-semibold">Performance</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <p className='font-normal mt-4'>For more information on these cookies, search the names of these cookies online to understand how exactly the collected information is used to suggest you product/services might be of interest to you.</p>

                <p className='text-xl font-bold mt-6'>Disabling cookies</p>
                <p className='font-normal mt-4'>We provide you with an option to accept or disable these cookies on your device as you visit the website. The selection is likewise recorded on the website.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Cookies
