import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMutation } from '@apollo/client';
import { INSERT_UPDATE_DELIVERY } from '../graphql/Mutation/Delivery.js';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SpinLoader from '../components/SpinLoader.js';
const DeliveryDetailsModel = ({ show, onClose, refetch, setdeliveryModal, setdeliveryShowModal, deliveryAddress, setDeliveryAddress, setSelectedDeliveryAddress }) => {
    const [insertUpdateDelivery] = useMutation(INSERT_UPDATE_DELIVERY);
    const [isLoading, setIsLoading] = useState(false);
    const [locationData, setLocationData] = useState({ city: '', state: '' });

    const handlePincodeChange = async (event, setFieldValue) => {
        const pincode = event.target.value.trim();
        if (pincode.length === 6) {
            try {
                const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
                const data = await response.json();

                if (data[0].Status === 'Success') {
                    const { District, State } = data[0].PostOffice[0];
                    setLocationData({ city: District, state: State });
                    setFieldValue('city', District);
                    setFieldValue('stateId', State);
                }
            } catch (error) {
            }
        }
        setFieldValue('zipCode', pincode);
    };
    const inserUpdateDelivery = async (values, { setSubmitting }) => {
        setdeliveryModal(false); setdeliveryShowModal(false);
        setIsLoading(true);
        try {
            const { data } = await insertUpdateDelivery({
                variables: {
                    input: {
                        "address1": values.address1,
                        "city": values.city,
                        "name": values.name,
                        "zipCode": values.zipCode,
                        "phone": values.phone,
                        "stateId": values.stateId,
                    }
                }
            });
            if (data?.insertUpdateDelivery) {
                setSelectedDeliveryAddress(data?.insertUpdateDelivery);
                toast.success("Delivery Address Added Successfully");
                setDeliveryAddress('');
                setLocationData('');
                refetch();
            }
        } catch (err) {
            toast.error(err);
        } finally {
            setIsLoading(false);
        }
    };
    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters long')
            .max(100, 'Name must be at most 100 characters long')
            .matches(/^[A-Za-z\s,.]+$/, 'Name can only contain letters, spaces, commas, and periods'),

        phone: Yup.string()
            .required('Mobile number is required')
            .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits'),

        address1: Yup.string()
            .required('Address is required')
            .min(6, 'Address must be at least 6 characters long')
            .max(150, 'Address must be at most 150 characters long'),

        zipCode: Yup.string()
            .required('Pincode is required')
            .matches(/^[0-9]{6}$/, 'Pincode must be exactly 6 digits'),

        city: Yup.string()
            .required('City is required')
            .min(2, 'City must be at least 2 characters long')
            .max(40, 'City must be at most 40 characters long')
            .matches(/^[A-Za-z\s,.]+$/, 'City can only contain letters, spaces, commas, and periods'),

        stateId: Yup.string()
            .required('State is required')
            .min(2, 'State must be at least 2 characters long')
            .max(40, 'State must be at most 40 characters long')
            .matches(/^[A-Za-z\s,.]+$/, 'State can only contain letters, spaces, commas, and periods'),
    });
    const handleChange = (e, setFieldValue) => {
        const cleanedValue = e.target.value.replace(/\s+/g, '');
        setFieldValue('phone', cleanedValue);
    };

    return (
        <>
            <Transition show={show}>
                <Dialog className="relative z-20" onClose={onClose}>
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-30 w-screen h-screen overflow-y-auto">
                        <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative w-screen transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="h-[75vh] overflow-y-auto bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div>
                                            <div className="mb-4">
                                                <h2 className="text-xl font-semibold">Add Delivery Address</h2>
                                            </div>
                                            <p className='border border-gray-100'></p>
                                            <Formik
                                                initialValues={{
                                                    name: deliveryAddress?.name || '',
                                                    phone: deliveryAddress?.phone || '',
                                                    address1: deliveryAddress?.address1 || '',
                                                    zipCode: deliveryAddress?.zipCode || '',
                                                    city: deliveryAddress?.city || '',
                                                    stateId: deliveryAddress?.stateId?.name || '',
                                                }}
                                                validationSchema={validationSchema}
                                                onSubmit={inserUpdateDelivery}
                                            >
                                                {({ isSubmitting, setFieldValue }) => (
                                                    <Form className="mt-6">
                                                        <div>
                                                            <h4 className="font-bold text-gray-800 mb-4">Delivery Contact Details</h4>
                                                            <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700">Name <span className="text-red-500">*</span></label>
                                                                    <Field
                                                                        type="text"
                                                                        name="name"
                                                                        placeholder="Name"
                                                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                    />
                                                                    <ErrorMessage name="name" component="div" className="text-red-500 text-xs mt-1" />
                                                                </div>
                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700">
                                                                        Mobile Number <span className="text-red-500">*</span>
                                                                    </label>
                                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                                                            +91
                                                                        </span>
                                                                        <Field
                                                                            type="number"
                                                                            name="phone"
                                                                            placeholder="Mobile Number"
                                                                            onChange={(e) => handleChange(e, setFieldValue)}
                                                                            className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name="phone" component="div" className="text-red-500 text-xs mt-1" />
                                                                    <p className="mt-1 text-xs text-gray-500">We’ll call this number to coordinate pickup</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h4 className="font-bold text-gray-800 mb-4 mt-7 md:mt-0">Address Details</h4>
                                                            <div className="mt-2 space-y-4">
                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700">Flat, Housing no., Building, Apartment - Area, street, sector <span className="text-red-500">*</span></label>
                                                                    <Field
                                                                        type="text"
                                                                        name="address1"
                                                                        placeholder="Flat, Housing no., Building, Apartment - Area, street, sector"
                                                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                    />
                                                                    <ErrorMessage name="address1" component="div" className="text-red-500 text-xs mt-1" />
                                                                </div>
                                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700">Pincode <span className="text-red-500">*</span></label>
                                                                        <Field
                                                                            type="text"
                                                                            name="zipCode"
                                                                            placeholder="Enter pincode"
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                            onChange={(event) => handlePincodeChange(event, setFieldValue)}
                                                                        />
                                                                        <ErrorMessage name="zipCode" component="div" className="text-red-500 text-sm" />

                                                                    </div>
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700">City <span className="text-red-500">*</span></label>
                                                                        <Field
                                                                            type="text"
                                                                            name="city"
                                                                            placeholder="Enter city"
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                            value={locationData.city}
                                                                        />
                                                                        <ErrorMessage name="city" component="div" className="text-red-500 text-sm" />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700">State <span className="text-red-500">*</span></label>
                                                                    <Field
                                                                        type="text"
                                                                        name="stateId"
                                                                        placeholder="Enter state"
                                                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                        value={locationData.state}
                                                                    />
                                                                    <ErrorMessage name="stateId" component="div" className="text-red-500 text-sm" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="mt-6 inline-flex w-full justify-center rounded-md bg-slate-900 px-3 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-slate-900 sm:mt-7"
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? (
                                                                <div className='flex justify-center'>
                                                                    <span className='me-2'>'Add Delivery Address'</span>
                                                                    <SpinLoader />
                                                                </div>
                                                            ) : (
                                                                'Add Delivery Address'
                                                            )}
                                                        </button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 space-y-2">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default DeliveryDetailsModel;

