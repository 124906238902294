import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { IoLocationSharp } from "react-icons/io5";
import { FaCalculator, FaTruckRampBox } from "react-icons/fa6";
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import { SlEnvolope } from "react-icons/sl";
import CalculateModel from './CalculateModel.js';
import { handleLogin } from '../Common/TokenLogin.js';
import { toast } from 'react-toastify';
import './calculatorCourier.css';
import { useQuery } from '@apollo/client';
import { ORDER_COMMITION } from '../graphql/Mutation/OrderCommition.js';
import Select from 'react-select';
import { TbLocationPin } from "react-icons/tb";
import { AiTwotoneSafetyCertificate } from "react-icons/ai";
import { GrSystem, GrSchedules } from "react-icons/gr";
import { GiSkills } from "react-icons/gi";
import p1Image from '../asset/img/p1.svg';
import { useLocation } from 'react-router-dom';
import docs from '../asset/img/gg1.jpg';
import domestic from '../asset/img/boxs.jpg';
import { BsBox } from 'react-icons/bs';


const validationSchema = Yup.object({
    pickUpPincode: Yup.string()
        .required('Pincode is required')
        .matches(/^[0-9]{6}$/, 'Pincode must be exactly 6 digits'),
    deliveryPincode: Yup.string()
        .required('Pincode is required')
        .matches(/^[0-9]{6}$/, 'Pincode must be exactly 6 digits'),
    weight: Yup.string().required('Weight is required')
});
const CalculatorCourier = () => {
    const location = useLocation();
    const imageurlcalcy = location?.state?.keyy
    // console.log('✌️imageurlcalcy --->', imageurlcalcy);
    const [openCal, setOpenCal] = useState(false);
    const [courierCharges, setCourierCharges] = useState([]);
    const [serviceData, setServiceData] = useState({});
    const { data: orderPercentageData } = useQuery(ORDER_COMMITION);
    const handleSubmit = async (values) => {
        setServiceData(values);
        let weight = 0;
        switch (values.weight) {
            case "0.5 Kg": weight = 500; break;
            case "1 Kg": weight = 1000; break;
            case "2 Kg": weight = 2000; break;
            case "3 Kg": weight = 3000; break;
            case "4 Kg": weight = 4000; break;
            case "5 Kg": weight = 5000; break;
            case "6 Kg": weight = 6000; break;
            case "7 Kg": weight = 7000; break;
            case "8 Kg": weight = 8000; break;
            case "9 Kg": weight = 9000; break;
            case "10 Kg": weight = 10000; break;
            case "11 Kg": weight = 11000; break;
            case "12 Kg": weight = 12000; break;
            case "13 Kg": weight = 13000; break;
            case "14 Kg": weight = 14000; break;
            case "15 Kg": weight = 15000; break;
            case "16 Kg": weight = 16000; break;
            case "17 Kg": weight = 17000; break;
            case "18 Kg": weight = 18000; break;
            case "19 Kg": weight = 19000; break;
            case "20 Kg": weight = 20000; break;
            case "21 Kg": weight = 21000; break;
            case "22 Kg": weight = 22000; break;
            case "23 Kg": weight = 23000; break;
            case "24 Kg": weight = 24000; break;
            case "25 Kg": weight = 25000; break;
            case "26 Kg": weight = 26000; break;
            case "27 Kg": weight = 27000; break;
            case "28 Kg": weight = 28000; break;
            case "29 Kg": weight = 29000; break;
            case "30 Kg": weight = 30000; break;
            default: weight = 0;
        }
        try {
            const authToken = await handleLogin();

            if (authToken) {
                const response = await fetch('https://shipment.xpressbees.com/api/courier/serviceability', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    body: JSON.stringify({
                        origin: values?.pickUpPincode,
                        destination: values?.deliveryPincode,
                        payment_type: 'prepaid',
                        weight: weight,
                        length: values?.length,
                        breadth: values?.breadth,
                        height: values?.height,
                    }),
                });
                const data = await response.json();

                if (response.ok && data?.status) {
                    let filteredData = [];
                    switch (true) {
                        case (weight === 500):
                            filteredData = data.data.filter(item => item.name === 'Surface Xpressbees 0.5 K.G');
                            break;
                        case (weight === 1000):
                            filteredData = data.data.filter(item => item.name === 'Xpressbees 1 K.G');
                            break;
                        case (weight === 2000 || weight === 3000 || weight === 4000):
                            filteredData = data.data.filter(item => item.name === 'Xpressbees 2 K.G');
                            break;
                        case (weight === 5000 || weight === 6000 || weight === 7000 || weight === 8000 || weight === 9000):
                            filteredData = data.data.filter(item => item.name === 'Xpressbees 5 K.G');
                            break;
                        case (weight >= 10000):
                            filteredData = data.data.filter(item => item.name === 'Xpressbees 10 K.G');
                            break;
                        default:
                            break;
                    }

                    let selectedData;
                    if (data?.data?.length > 1 && filteredData.length > 0) {
                        selectedData = filteredData[0];
                    } else if (!data?.data?.some(item => item.name === 'Xpressbees 10 K.G')) {
                        throw new Error(`Service for (pincode) ${values?.pickUpPincode} (weight) ${values?.weight} is not available`);
                    } else {
                        throw new Error('Pickup pincode not serviceable');
                    }
                    const orderPercentage = orderPercentageData?.getCommitionAmount?.order_percentage;
                    const percentageMultiplier = 1 + (orderPercentage / 100);

                    const updatedCharges = {
                        ...selectedData,
                        freight_charges: percentageMultiplier ? selectedData.freight_charges * percentageMultiplier : selectedData.freight_charges,
                        total_charges: percentageMultiplier ? selectedData.total_charges * percentageMultiplier : selectedData.total_charges,
                    };

                    setCourierCharges([updatedCharges]);
                    setOpenCal(true);

                } else {
                    toast.error(data?.data);
                }
            }
        } catch (error) {
            toast.error(error?.message);
        }
    };

    const weightOptions = [
        { value: "0.5 Kg", label: "0.5 KG" },
        { value: "1 Kg", label: "1 Kg" },
        { value: "2 Kg", label: "2 Kg" },
        { value: "3 Kg", label: "3 Kg" },
        { value: "4 Kg", label: "4 Kg" },
        { value: "5 Kg", label: "5 Kg" },
        { value: "6 Kg", label: "6 Kg" },
        { value: "7 Kg", label: "7 Kg" },
        { value: "8 Kg", label: "8 Kg" },
        { value: "9 Kg", label: "9 Kg" },
        { value: "10 Kg", label: "10 Kg" },
        { value: "11 Kg", label: "11 Kg" },
        { value: "12 Kg", label: "12 Kg" },
        { value: "13 Kg", label: "13 Kg" },
        { value: "14 Kg", label: "14 Kg" },
        { value: "15 Kg", label: "15 Kg" },
        { value: "16 Kg", label: "16 Kg" },
        { value: "17 Kg", label: "17 Kg" },
        { value: "18 Kg", label: "18 Kg" },
        { value: "19 Kg", label: "19 Kg" },
        { value: "20 Kg", label: "20 Kg" },
        { value: "21 Kg", label: "21 Kg" },
        { value: "22 Kg", label: "22 Kg" },
        { value: "23 Kg", label: "23 Kg" },
        { value: "24 Kg", label: "24 Kg" },
        { value: "25 Kg", label: "25 Kg" },
        { value: "26 Kg", label: "26 Kg" },
        { value: "27 Kg", label: "27 Kg" },
        { value: "28 Kg", label: "28 Kg" },
        { value: "29 Kg", label: "29 Kg" },
        { value: "30 Kg", label: "30 Kg" },
    ];
    const handleReset = (resetForm) => {
        resetForm({
            values: {
                pickUpPincode: '',
                deliveryPincode: '',
                weight: '',
                length: '',
                height: '',
                breadth: '',
            }
        });
    };
    const handleChange = (e, setFieldValue, fieldName) => {
        const cleanedValue = e.target.value.replace(/\D/g, '');
        setFieldValue(fieldName, cleanedValue);
    };
    const services = [
        {
            title: 'Our Footprint',
            description:
                'We have an extensive reach and strong footprint with over 3,700+ facility centers strategically located across 29 states and 6 union territories, offering end-to-end logistics solutions in more than 27,000+ PIN codes.',
            icon: '📍',
        },
        {
            title: 'Dedicated Fleet Network',
            description:
                'Our integrated transportation network comprising of over 1,300 runs daily, serviced through dedicated fleet-runs, provides unmatched capability to transport anywhere in India.',
            icon: '🚚',
        },
        {
            title: 'Speed and Reliability',
            description:
                'Our delivery of orders on committed schedules enabled through strategically positioned hubs and dedicated-GPS enabled fleet of vehicles, provide unmissable speed and reliability.',
            icon: '⚡',
        },
        {
            title: 'Technology and Automation',
            description:
                'Our quest for technology and automation to efficiently delivery orders is never-ending. We enable order fulfillment by engaging next-generation cutting-edge technology, data sciences and seamless integration at every step.',
            icon: '🤖',
        },
        {
            title: 'Professional and Skilled Force',
            description:
                'Our 50,000+ well-trained and skilled force ensure highest standards of quality, performance and response 24 X 7 everyday. They are powered by optimal logistics solutions.',
            icon: '💼',
        },
        {
            title: 'Smart Scheduling',
            description:
                'Our digitized end-to-end operations enhance the speed of our pick-up and delivery services leading to utmost efficiency through improved utilization.',
            icon: '📅',
        },
    ];

    return (
        <>
            <Header />
            <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:pt-14">
                <img
                    src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                />
                <div
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="mx-auto  max-w-7xl md:px-8 lg:px-8">
                    <div className='flex flex-col lg:flex-row lg:space-x-3 gap-y-10'>
                        <div className="lg:w-2/3 md:rounded-l-lg rounded-none md:py-8 md:px-9 p-6 bg-white ring-1 ring-slate-900/5 shadow-md">
                            <div className="flex justify-between mb-5">
                                <div className="flex">
                                    {imageurlcalcy ? <BsBox className="text-3xl me-3 text-gray-800 drop-shadow-lg" /> : <SlEnvolope className="text-3xl me-3 text-gray-800 drop-shadow-lg" />}
                                    {imageurlcalcy ? <h1 className="text-xl font-bold">Domestic Rates Calculator</h1> : <h1 className="text-xl font-bold">Document Rates Calculator</h1>}
                                </div>
                            </div>
                            <p className="mb-5 text-sm">Our free calculator helps you calculate courier charges based on everything that matters.</p>
                            <Formik
                                initialValues={{
                                    pickUpPincode: '',
                                    deliveryPincode: '',
                                    weight: '',
                                    length: '',
                                    height: '',
                                    breadth: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}

                            >
                                {({ isSubmitting, resetForm, setFieldValue }) => (
                                    <>
                                        <p className="text-gray-400 inline-block font-normal mb-6 underline decoration-dashed underline-offset-8 cursor-pointer" onClick={() => handleReset(resetForm)}>Reset</p>
                                        <Form>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-7">
                                                <div>
                                                    <label>Pick-up Pincode <span className="text-red-500">*</span></label>
                                                    <div className="mt-2">
                                                        <div className="flex items-center border border-slate-700 p-1.5 rounded-md">
                                                            <IoLocationSharp className="text-gray-500 mr-2" />
                                                            <Field
                                                                type="text"
                                                                name="pickUpPincode"
                                                                className="w-full focus:outline-none"
                                                                placeholder="Pick-up"
                                                                onChange={(e) => handleChange(e, setFieldValue, 'pickUpPincode')}
                                                            />
                                                        </div>
                                                        <ErrorMessage name="pickUpPincode" component="div" className="text-red-600 text-sm mt-1" />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label>Delivery Pincode <span className="text-red-500">*</span></label>
                                                    <div className="mt-2">
                                                        <div className="flex items-center border border-slate-700 p-1.5 rounded-md">
                                                            <IoLocationSharp className="text-gray-500 mr-2" />
                                                            <Field
                                                                type="text"
                                                                name="deliveryPincode"
                                                                className="w-full focus:outline-none"
                                                                placeholder="Delivery"
                                                                onChange={(e) => handleChange(e, setFieldValue, 'deliveryPincode')}
                                                            />
                                                        </div>
                                                        <ErrorMessage name="deliveryPincode" component="div" className="text-red-600 text-sm mt-1" />
                                                    </div>
                                                </div>
                                                <div className="relative z-50">
                                                    <label>Weight (Kg) <span className="text-red-500">*</span></label>
                                                    <div className="mt-2 z-50">
                                                        <Field name="weight">
                                                            {({ field, form }) => {
                                                                const selectedOption = weightOptions.find(option => option.value === field.value);
                                                                return (
                                                                    <Select
                                                                        value={selectedOption}
                                                                        options={weightOptions}
                                                                        className="w-full focus:outline-none z-50"
                                                                        styles={{
                                                                            control: (base, state) => ({
                                                                                ...base,
                                                                                borderColor: state.isFocused ? '#2b2b2b' : '#2b2b2b',
                                                                                '&:hover': { borderColor: '#2b2b2b' },
                                                                                boxShadow: state.isFocused ? '0 0 0 0px #000000' : 'none',
                                                                                zIndex: 1050,
                                                                            }),
                                                                            singleValue: (base) => ({
                                                                                ...base,
                                                                                color: '#000000',
                                                                                zIndex: 1050,
                                                                            }),
                                                                            option: (base, state) => ({
                                                                                ...base,
                                                                                backgroundColor: state.isSelected ? '#000000' : state.isFocused ? '#f0f0f0' : 'white',
                                                                                color: state.isSelected ? 'white' : '#000000',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#f0f0f0',
                                                                                    color: '#000000',
                                                                                },
                                                                                zIndex: 1050,
                                                                            }),
                                                                            menu: (base) => ({
                                                                                ...base,
                                                                                zIndex: 1050,
                                                                                position: 'absolute',
                                                                            }),
                                                                        }}
                                                                        isSearchable={false}
                                                                        placeholder="Select weight"
                                                                        onChange={option => form.setFieldValue(field.name, option.value)}
                                                                        onBlur={() => form.setFieldTouched(field.name, true)}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                        <ErrorMessage name="weight" component="div" className="text-red-600 text-sm mt-1 z-50" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-end mt-6">
                                                <button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-800 text-white hover:bg-gray-900 focus:outline-none text-sm"
                                                >
                                                    <FaCalculator className="text-white me-2 text-lg" />
                                                    Calculate
                                                </button>
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                        <div className="w-full lg:w-1/3 bg-white p-6 md:rounded-r-lg shadow-md mt-6 lg:mt-0">
                            {imageurlcalcy ? <img src={domestic} alt="" className='rounded-lg hover:opacity-80' /> : <img src={docs} alt="" className='rounded-lg hover:opacity-80' />}
                            <h2 className="text-xl font-semibold my-4">All our plans include
                                the essentials</h2>
                            <div className="space-y-2">
                                <h3 className="text-slate-600 text-sm">● We arrive at your doorstep</h3>
                                <h3 className="text-slate-600 text-sm">● Secure your parcel</h3>
                                <h3 className="text-slate-600 text-sm">● Free pick-up and delivery at your doorstep</h3>
                                <h3 className="text-slate-600 text-sm">● Automated shipment tracking</h3>
                                <h3 className="text-slate-600 text-sm">● Live rate calculator</h3>
                                <h3 className="text-slate-600 text-sm">● Multi pickup location</h3>
                                <h3 className="text-slate-600 text-sm">● Manifestation and labels</h3>
                                <h3 className="text-slate-600 text-sm">● Reverse logistics services</h3>
                                <h3 className="text-slate-600 text-sm">● digital payment solutions</h3>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 mt-7 md:rounded-lg">
                        <div className='grid md:grid-cols-2 grid-cols-1'>
                            <div>
                                <p className='font-bold text-3xl'>Selection of Boxes</p>
                                <p className='font-bold mt-4 mb-3 text-lg'>Material</p>
                                <p className='text-sm text-slate-600 mb-2'>● Use corrugated boxes of 3 ply for products below 4Kg and not fragile</p>
                                <p className='text-sm text-slate-600 mb-2'>● Use corrugated boxes of 5 ply for products above 4Kg and fragile</p>
                                <p className='text-sm text-slate-600 mb-2'>● Use tamper evident boxes for high value items (Cost above Rs. 7000)</p>
                                <p className='text-sm text-slate-600 mb-2'>● Ensure the material used can withstand the required edgecrush and burst factor loads</p>
                                <p className='text-sm text-slate-600 mb-2'>● Optimize the size of the boxes as per the product dimensions</p>
                                <p className='text-sm text-slate-600 mb-2'>● The box should not be too small or too big for the item shipped. An optimum size box should be chosen</p>
                                <p className='text-sm text-slate-600 mb-2'>● Inspect the box for holes, tears, crushed edges and sturdiness. Avoid poor quality boxes for the safety of the item shipped</p>
                                <p className='font-bold mt-7 mb-3 text-lg'>Size</p>
                                <p className='text-sm text-slate-600 mb-1.5'>● Optimize the size of the boxes as per the product dimensions</p>
                                <p className='text-sm text-slate-600 mb-1.5'>● The box should not be too small or too big for the item shipped. An optimum size box should be chosen</p>
                                <p className='font-bold mt-7 mb-3 text-lg'>Quick Check</p>
                                <p className='text-sm text-slate-600 mb-1.5'>● Inspect the box for holes, tears, crushed edges and sturdiness. Avoid poor quality boxes for the safety of the item shipped</p>
                            </div>
                            <div className='space-y-6 mx-auto'>
                                <img src={p1Image} alt="" />
                            </div>
                        </div>
                        <div className='grid grid-cols-1 mt-10 rounded-none md:rounded-lg md:grid-cols-2 lg:grid-cols-3 bg-white mb-7 xl:grid-cols-2 gap-y-9 gap-x-8'>
                            <div className=''>
                                <div className="flex items-start">
                                    <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                        <TbLocationPin className="h-6 w-6 text-black" aria-hidden="true" />
                                    </div>
                                    <p className=" font-semibold self-center ms-3 text-black">Our Footprint</p>

                                </div>
                                <p className="mt-1 text-gray-500 text-sm">
                                    We have an extensive reach and strong footprint with over 1,200+ facility centers strategically located across 12 states and 5 union territories, offering end-to-end logistics solutions in more than 35,000+ PIN codes.
                                </p>
                            </div>
                            <div className=''>
                                <div className="flex items-start">
                                    <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                        <FaTruckRampBox className="h-6 w-6 text-black" aria-hidden="true" />
                                    </div>
                                    <p className=" font-semibold self-center ms-3 text-black">Dedicated Fleet Network</p>

                                </div>
                                <p className="mt-1 text-gray-500 text-sm">
                                    Our integrated transportation network comprising of over 1,000 runs daily, serviced through dedicated fleet-runs, provides unmatched capability to transport anywhere in India.
                                </p>
                            </div>
                            <div className=''>
                                <div className="flex items-start">
                                    <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                        <AiTwotoneSafetyCertificate className="h-6 w-6 text-black" aria-hidden="true" />
                                    </div>
                                    <p className=" font-semibold self-center ms-3 text-black">Speed and Reliability</p>

                                </div>
                                <p className="mt-1 text-gray-500 text-sm">
                                    Our delivery of orders on committed schedules enabled through strategically positioned hubs and dedicated-GPS enabled fleet of vehicles, provide unmissable speed and reliability.
                                </p>
                            </div>
                            <div className=''>
                                <div className="flex items-start">
                                    <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                        <GrSystem className="h-6 w-6 text-black" aria-hidden="true" />
                                    </div>
                                    <p className=" font-semibold self-center ms-3 text-black">Technology and Automation</p>

                                </div>
                                <p className="mt-1 text-gray-500 text-sm">
                                    Our quest for technology and automation to efficiently delivery orders is never-ending. We enable order fulfillment by engaging next-generation cutting-edge technology, data sciences and seamless integration at every step.
                                </p>
                            </div>
                            <div className=''>
                                <div className="flex items-start">
                                    <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                        <GiSkills className="h-6 w-6 text-black" aria-hidden="true" />
                                    </div>
                                    <p className=" font-semibold self-center ms-3 text-black">Professional and Skilled Force</p>

                                </div>
                                <p className="mt-1 text-gray-500 text-sm">
                                    Our 15,000+ well-trained and skilled force ensure highest standards of quality, performance and response 24 X 7 everyday. They are powered by optimal logistics solutions.
                                </p>
                            </div>
                            <div className=''>
                                <div className="flex items-start">
                                    <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                        <GrSchedules className="h-6 w-6 text-black" aria-hidden="true" />
                                    </div>
                                    <p className=" font-semibold self-center ms-3 text-black">Smart Scheduling</p>

                                </div>
                                <p className="mt-1 text-gray-500 text-sm">
                                    Our digitized end-to-end operations enhance the speed of our pick-up and delivery services leading to utmost efficiency through improved utilization.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {openCal && serviceData && <CalculateModel show={openCal} onClose={() => setOpenCal(false)} serviceData={serviceData} courierCharges={courierCharges} />}
            <div>
                <Footer />
            </div>
        </>
    )
}

export default CalculatorCourier;
