import React, { useEffect } from 'react'
import { GoCircle } from "react-icons/go";
import { SlLocationPin } from "react-icons/sl";
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import PriceCalculator from '../DocumentShiping/priceCalculator.js';
import { RiWeightLine } from "react-icons/ri";
import { Link } from 'react-router-dom';

const CalculateModel = ({ show, onClose, serviceData, courierCharges }) => {
    if (!show) return null;
    return (
        <div>
            <Transition show={show}>
                <Dialog className="relative z-10" onClose={onClose}>
                    <TransitionChild
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                    </TransitionChild>

                    <div className="fixed inset-0 z-10 w-screen h-screen overflow-y-auto">
                        <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full w-screen sm:max-w-lg">
                                    <div className="h-[75vh] overflow-y-auto bg-white px-4 pt-5 sm:px-6 sm:pt-6 sm:pb-1 space-y-5">
                                        <div>
                                            <p className='text-xl mb-5 font-bold'>Billing - Price Calculator</p>
                                            <p className='border border-slate-100'></p>
                                        </div>
                                        <div className='space-y-4'>
                                            <p className='text-md font-semibold'>Address Details </p>
                                            <div className='flex space-x-4'>
                                                <div className='space-y-1 text-gray-400'>
                                                    <GoCircle />
                                                    <ul className='ms-1.5'>
                                                        <li className='text-xs leading-3'>I</li>
                                                        <li className='text-xs leading-3'>I</li>
                                                    </ul>
                                                    <SlLocationPin />
                                                </div>

                                                <div className='space-y-6'>
                                                    <div>
                                                        <p className='text-sm font-semibold mb-1'>{serviceData?.pickUpPincode}  <span className='ms-1 text-gray-400'>(Free Pickup)</span></p>
                                                    </div>
                                                    <div>
                                                        <p className='text-sm font-semibold mb-1'>{serviceData?.deliveryPincode}  <span className='ms-1 text-gray-400'>(delivery)</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='border border-slate-100'></p>
                                        <div className='space-y-2'>
                                            <p className='font-semibold'>Size</p>
                                            <div className='text-sm font-semibold flex justify-between'>
                                                <p className='text-gray-500'>Weight (KG)</p>
                                                <div className='flex'>
                                                    <p className='me-4 text-slate-500 self-center text-lg'><RiWeightLine /></p>
                                                    <p>{serviceData?.weight}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='border border-slate-100'></p>
                                        <div className='md:px-0 px-0'>
                                            <PriceCalculator orderAmount={Math.round(courierCharges[0].total_charges)} paymentModal='true' />

                                        </div>
                                    </div>
                                    <div className='bg-gray-50 pb-4 pt-3'>
                                        <div className='flex justify-between text-sm md:font-semibold  pl-4 pr-4 md:pl-6 lg:pr-10 md:pr-6 ' >
                                            <p className='text-xl font-semibold'>Total Amount</p>
                                            <p className='text-xl font-semibold'>₹{Math.round(courierCharges[0].total_charges)}</p>
                                        </div>

                                        <div className=" mt-4 sm:flex sm:flex-row-reverse  px-4 sm:px-6">
                                            <Link to='/documentshipping'> <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-slate-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-900 sm:ml-3 sm:w-auto"
                                                onClick={onClose}
                                            >
                                                Ship Now
                                            </button></Link>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={onClose}
                                                data-autofocus
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </div>
    )
}

export default CalculateModel
