import React, { useEffect, useState } from 'react'
import AdminHeader from './AdminHeader.js'
import { GoCircle } from "react-icons/go";
import { SlLocationPin } from "react-icons/sl";
import { useQuery } from '@apollo/client';
import { GET_ALL_USER } from '../graphql/Query/Signin.js';
import { useLocation } from 'react-router-dom';
import { GET_ORDERS_BY_ID } from '../graphql/Query/Order.js';
import moment from 'moment';
import PriceCalculator from '../DocumentShiping/priceCalculator.js';
import { handleLogin } from '../Common/TokenLogin.js';


const AdminTrack = () => {
    const [trackingData, setTrackingData] = useState(null);
    const location = useLocation();
    const getId = location?.state?.id;
    const { data: GetOrderOrder, loading, refetch } = useQuery(GET_ORDERS_BY_ID, {
        variables: {
            "getOrderId": getId
        }
    });

    useEffect(() => {
        const trackShipment = async () => {
            const url = `https://shipment.xpressbees.com/api/shipments2/track/${GetOrderOrder?.getOrder?.awb_number}`;
            const authToken = await handleLogin();
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setTrackingData(data);
            } catch (err) {
                console.error('Error tracking shipment:', err);
            }
        };

        if (GetOrderOrder?.getOrder?.awb_number) {
            trackShipment();
        }
    }, [GetOrderOrder?.getOrder?.awb_number]);

    return (
        <div>
            <AdminHeader />
            <div className='mx-auto md:pt-14 pt-9 pb-9 max-w-7xl px-6 md:space-y-10 space-y-9 lg:px-8'>
                <p className='text-2xl font-semibold'>Track Courier Details</p>
                <div className='flex justify-between'>
                    <div className='space-y-2'>
                        <p className='text-sm font-semibold text-gray-500'>Order ID <span>{GetOrderOrder?.
                            getOrder
                            ?.order_id}</span></p>
                        <p className='text-sm font-semibold text-gray-500'>AWB NO. <span>{GetOrderOrder?.getOrder?.awb_number}</span></p>
                        <p className='text-sm text-gray-500'>{moment(GetOrderOrder?.getOrder?.createdAt).format('DD/MM/YYYY')}</p>
                    </div>
                </div>
                <div className='grid gap-x-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-9'>
                    <div className='p-5 border border-slate-300 rounded-lg'>
                        <div className='space-y-4'>
                            <p className='text-md font-semibold'>Address Details </p>
                            <div className='flex space-x-4'>
                                <div className='space-y-1 text-gray-400'>
                                    <GoCircle />
                                    <ul className='ms-1.5'>
                                        <li className='text-xs leading-3'>I</li>
                                        <li className='text-xs leading-3'>I</li>
                                        <li className='text-xs leading-3'>I</li>
                                        <li className='text-xs leading-3'>I</li>
                                        <li className='text-xs leading-3'>I</li>
                                        <li className='text-xs leading-3 sm:hidden'>I</li>
                                        <li className='text-xs leading-3 sm:hidden'>I</li>
                                    </ul>
                                    <SlLocationPin />
                                </div>
                                <div className='space-y-4'>
                                    <div>
                                        <p className='text-sm font-semibold mb-1'>{GetOrderOrder?.
                                            getOrder
                                            ?.pickupAddress?.name}  <span className='ms-1 text-gray-400'>(Pickup)</span></p>
                                        <p className='text-sm text-gray-500'>{GetOrderOrder?.
                                            getOrder
                                            ?.pickupAddress?.address1} , {GetOrderOrder?.
                                                getOrder
                                                ?.pickupAddress?.city} , {GetOrderOrder?.
                                                    getOrder
                                                    ?.pickupAddress?.stateId?.name} - {GetOrderOrder?.
                                                        getOrder
                                                        ?.pickupAddress?.zipCode}</p>
                                        <p className='text-sm text-gray-500'>{GetOrderOrder?.
                                            getOrder
                                            ?.pickupAddress?.phone}</p>
                                    </div>
                                    <div>
                                        <p className='text-sm font-semibold mb-1'>{GetOrderOrder?.
                                            getOrder
                                            ?.deliveryAddress?.name} <span className='ms-1 text-gray-400'>(delivery)</span></p>
                                        <p className='text-sm text-gray-500'>{GetOrderOrder?.
                                            getOrder
                                            ?.deliveryAddress?.address1} , {GetOrderOrder?.
                                                getOrder
                                                ?.deliveryAddress?.city} , {GetOrderOrder?.
                                                    getOrder
                                                    ?.deliveryAddress?.stateId?.name} - {GetOrderOrder?.
                                                        getOrder
                                                        ?.deliveryAddress?.zipCode}</p>
                                        <p className='text-sm text-gray-500'>{GetOrderOrder?.
                                            getOrder
                                            ?.deliveryAddress?.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {trackingData && (
                        <div className='p-5 border border-slate-300 rounded-lg'>
                            <p className='text-md font-semibold pb-4'>Tracking Information</p>
                            <div className='space-y-2'>
                                <p className='font-medium text-sm'>AWB Number: {trackingData?.data?.awb_number}</p>
                                <p className='font-medium text-sm'>Status: {trackingData?.data?.status}</p>
                                <div className='border-t pt-3 space-y-3 '>
                                    <p className='text-sm font-semibold '>History:</p>
                                    {trackingData?.data?.history?.map((event, index) => (
                                        <div key={index} className='text-sm text-gray-500'>
                                            <p>{event?.event_time} - {event?.location}</p>
                                            <p>{event?.message}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}


                    <div className='p-5 border border-slate-300 rounded-lg'>
                        <PriceCalculator orderAmount={GetOrderOrder?.getOrder?.order_amount} />
                    </div>

                    <div className='p-5 border border-slate-300 rounded-lg'>
                        <p className='text-md font-semibold pb-4'>Package Information</p>
                        <div className='space-y-3'>
                            <div className='flex justify-between'>
                                <p className='text-sm text-gray-500'>Product :</p>
                                <p className='text-sm'>{GetOrderOrder?.
                                    getOrder
                                    ?.ProductName}</p>
                            </div>
                            <div className='flex justify-between'>
                                <p className='text-sm text-gray-500'>Valuation :</p>
                                <p className='text-sm'>Rs.{GetOrderOrder?.
                                    getOrder
                                    ?.ProductPrice}</p>
                            </div>
                            <div className='flex justify-between'>
                                <p className='text-sm text-gray-500'>Courier :</p>
                                <p className='text-sm'>{GetOrderOrder?.
                                    getOrder
                                    ?.courier_name}</p>
                            </div>
                            <div className='flex justify-between'>
                                <p className='text-sm text-gray-500'>Weight :</p>
                                <p className='text-sm'>{GetOrderOrder?.
                                    getOrder
                                    ?.package_weight_Length}</p>
                            </div>
                            <div className='flex justify-between'>
                                <p className='text-sm text-gray-500'>Status :</p>
                                <p className='text-sm'>{GetOrderOrder?.
                                    getOrder
                                    ?.trackStatus}</p>
                            </div>
                            <div className='flex justify-between'>
                                <p className='text-sm text-gray-500'>Amount :</p>
                                <p className='text-sm'>{GetOrderOrder?.
                                    getOrder
                                    ?.order_amount}</p>
                            </div>
                        </div>
                    </div>

                    <div className='p-5 border border-slate-300 rounded-lg'>
                        {GetOrderOrder?.
                            getOrder
                            ?.paymentDetails.map((items) => {
                                return (
                                    <>
                                        <p className='text-lg font-semibold pb-4'>Payment Details</p>
                                        <div className='space-y-3'>

                                            <p className='text-md font-semibold'>Payment Method</p>
                                            <div className='flex justify-between'>
                                                <p className='text-sm text-gray-500'>Payment Type :</p>
                                                <p className='text-sm'>{items?.payment_group}</p>
                                            </div>
                                            <div className='flex justify-between'>
                                                <p className='text-sm text-gray-500'>Receive :</p>
                                                <p className='text-sm'>{items?.payment_method?.upi?.channel}</p>
                                            </div>
                                            <div className='flex justify-between'>
                                                <p className='text-sm text-gray-500'>UPI ID :</p>
                                                <p className='text-sm'>{items?.payment_method?.upi?.upi_id}</p>
                                            </div>
                                            <div className='flex justify-between'>
                                                <p className='text-sm text-gray-500'>Status :</p>
                                                <p className='text-sm'>{items?.payment_status}</p>
                                            </div>


                                            <p className='text-md font-semibold'>Payment Gateway Details</p>
                                            <div className='flex justify-between'>
                                                <p className='text-sm text-gray-500'>Payment Order ID :</p>
                                                <p className='text-sm'>{items?.payment_gateway_details?.gateway_order_id}</p>
                                            </div>
                                            <div className='flex justify-between'>
                                                <p className='text-sm text-gray-500'>Payment ID :</p>
                                                <p className='text-sm'>{items?.payment_gateway_details?.gateway_payment_id}</p>
                                            </div>

                                            <div className='flex justify-between'>
                                                <p className='text-sm text-gray-500'>Payment Amount :</p>
                                                <p className='text-sm'>{items?.payment_amount}</p>
                                            </div>
                                            <div className='flex justify-between'>
                                                <p className='text-sm text-gray-500'>Payment Date :</p>
                                                <p className='text-sm'>{items?.payment_completion_time}</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminTrack
