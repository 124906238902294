import React, { useEffect, useState } from 'react'
import AdminHeader from './AdminHeader.js'
import { Link } from 'react-router-dom';
import { GET_ALL_USER } from '../graphql/Query/Signin.js';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Loader from '../components/Loader.js';
import CustomPagination from '../components/Pagination.js';

const AdminTotalCustomer = () => {
    const [searchUser, setSearchUser] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [todayCustomerCount, setTodayCustomerCount] = useState(0);
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);
    const [limit] = useState(100);
    const { data, loading } = useQuery(GET_ALL_USER, {
        variables: {
            page: page + 1,
            limit,
            "sort": {
                "key": "createdAt",
                "type": -1
            },
            "search": filter,
            "from": fromDate,
            "to": toDate
        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (data?.getAllUser?.data) {
            const today = moment().startOf('day');
            const todayUser = data?.getAllUser?.data?.filter((ticket) => moment(ticket.createdAt).isSame(today, 'day'));
            setTodayCustomerCount(todayUser?.length);
        }
    }, [data])
    const fromDateChange = (e) => {
        setFromDate(e.target.value);
    }

    const toDateChange = (e) => {
        setToDate(e.target.value)
    }
    const searchCustomerUser = () => {
        setFilter(searchUser)
    }

    const handleSearch = (e) => {
        searchCustomerUser()
    }
    const handleReset = () => {
        setFromDate('');
        setToDate('');
        setFilter('');
        setSearchUser('');
    }


    const handlePageClick = (selectedPage) => {
        setPage(selectedPage);
      };
    return (
        <div>
            <div>
                <AdminHeader />


                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 my-10 space-y-10">
                    <div className='flex justify-between'>
                        <h2 className="text-lg font-semibold">Total Customer</h2>
                        <h2 className="text-lg font-semibold">Today Customer - {todayCustomerCount}</h2>
                    </div>
                    <div className='p-4 max-w-full mx-auto bg-gray-50 ring-1 ring-gray-200 rounded-xl space-y-4'>
                        <div className='flex justify-between'>
                            <h2 className="text-lg font-semibold">Orders Filters</h2>
                            <button className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300" onClick={handleReset}>
                                Reset
                            </button>
                        </div>

                        <div className='lg:flex space-y-7 lg:space-y-0 lg:justify-between'>
                            <div>
                                <p className="text-sm font-medium text-gray-700">Search</p>
                                <div className='md:flex md:space-x-4 space-x-0'>
                                    <div>
                                        <input type="text" onChange={(e) => setSearchUser(e.target.value)} onKeyDown={handleSearch} value={searchUser} placeholder="Search..." className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none p-1.5 border rounded-md" />
                                    </div>
                                </div>
                            </div>

                            <div className='md:flex md:space-x-4 space-x-0 space-y-3 md:space-y-0'>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">From</p>
                                    <div className='md:flex md:space-x-4 space-x-0'>
                                        <div>
                                            <input
                                                type="date"
                                                className="mt-1 w-full md:w-none p-1.5 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 border rounded-md"
                                                value={fromDate}
                                                onChange={(e) => { fromDateChange(e) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">To</p>
                                    <div className='md:flex md:space-x-4 space-x-0'>
                                        <div>
                                            <input
                                                type="date"
                                                className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none p-1.5 border rounded-md"
                                                value={toDate}
                                                onChange={(e) => toDateChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='justify-end flex'>
                        {/* <div className='mt-2'>
                            <label>
                                Items per page:
                                <select value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
                                    <option value={100}>100</option>
                                    <option value={50}>50</option>
                                </select>
                            </label>
                        </div> */}

                        <div>
      <CustomPagination
        totalItems={data?.getAllUser?.count || 0}
        limit={limit}
        pageNo={page}
        handlePageClick={handlePageClick}
      />
      {/* Render your user list here */}
    </div>
                    </div>

                    <div className="overflow-x-auto rounded-t-xl  border border-gray-200">

                        <p className='font-semibold m-3'>Total : {data?.getAllUser?.count}</p>
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">No.</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Register Date</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Mobile no</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Email ID</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Privacy Policy</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Verify</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Full Details</td>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && <Loader />}
                                {data && data?.getAllUser?.count > 0 && data?.getAllUser?.data &&
                                    data?.getAllUser?.data?.map((row, index) => (
                                        <tr key={index} className='hover:bg-gray-50'>
                                            <td className="py-2.5 text-sm px-4 border-b">{index + 1}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{moment(row?.createdAt).format('DD/MM/YYYY')}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.mobile}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.email}</td>
                                            <td
                                                className={`py-2.5 text-sm font-semibold px-4 border-b ${row?.ApprovePrivacyPolicy ? 'text-green-400' : 'text-red-400'
                                                    }`}
                                            >
                                                {row?.ApprovePrivacyPolicy ? 'Accepted' : 'Not Accepted'}
                                            </td>
                                            <td
                                                className={`py-2.5 text-sm font-semibold px-4 border-b ${row?.verified ? 'text-green-400' : 'text-red-400'
                                                    }`}
                                            >
                                                {row?.verified ? 'Verified' : 'Not Verified'}
                                            </td>
                                            <td className="py-2.5 text-sm px-4 border-b text-blue-400 font-semibold"><Link to='/admintrack'> View Details</Link></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminTotalCustomer
