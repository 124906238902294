import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMutation } from '@apollo/client';
import { RECEIVER_DATA } from '../graphql/Mutation/Receiver.js';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SpinLoader from '../components/SpinLoader.js';

const AddressDetailsModel = ({ show, onClose, refetch, setShowModalAddress, setShowModal, setPickupAddress, pickupAddress, setSelectedPickupAddress }) => {
    const [AddReceiver] = useMutation(RECEIVER_DATA);
    const validationSchema = Yup.object({
        warehouse_name: Yup.string()
            .required('Home/ Shop/ Your name is required')
            .min(3, 'Home/ Shop/ Your name must be at least 3 characters long')
            .max(20, 'Home/ Shop/ Your name must be at most 20 characters long')
            .matches(/^[A-Za-z\s,.]+$/, 'Home/ Shop/ Your name can only contain letters, spaces, commas, and periods'),
    
        name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters long')
            .max(100, 'Name must be at most 100 characters long')
            .matches(/^[A-Za-z\s,.]+$/, 'Name can only contain letters, spaces, commas, and periods'),
    
        phone: Yup.string()
            .required('Mobile number is required')
            .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits'),
    
        address1: Yup.string()
            .required('Address is required')
            .min(6, 'Address must be at least 6 characters long')
            .max(100, 'Address must be at most 100 characters long'),
    
        zipCode: Yup.string()
            .required('Pincode is required')
            .matches(/^[0-9]{6}$/, 'Pincode must be exactly 6 digits'),
    
        city: Yup.string()
            .required('City is required')
            .min(1, 'City name must be at least 1 character long')
            .max(40, 'City name must be at most 40 characters long')
            .matches(/^[A-Za-z\s,.]+$/, 'City name can only contain letters, spaces, commas, and periods'),
    
        stateId: Yup.string()
            .required('State is required')
            .min(1, 'State name must be at least 1 character long')
            .max(40, 'State name must be at most 40 characters long')
            .matches(/^[A-Za-z\s,.]+$/, 'State name can only contain letters, spaces, commas, and periods'),
    });
    const [isLoading, setIsLoading] = useState(false);
    const [locationData, setLocationData] = useState({ city: '', state: '' });

    const handlePincodeChange = async (event, setFieldValue) => {
        const pincode = event.target.value.trim();
        if (pincode.length === 6) {
            try {
                const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
                const data = await response.json();

                if (data[0].Status === 'Success') {
                    const { District, State } = data[0].PostOffice[0];
                    setLocationData({ city: District, state: State });
                    setFieldValue('city', District);
                    setFieldValue('stateId', State);
                }
            } catch (error) {
            } 
        }
        setFieldValue('zipCode', pincode);
    };
    const AddReceiverData = async (values) => {
        setShowModalAddress(false); setShowModal(false);
        setIsLoading(true);
        try {
            const { data } = await AddReceiver({
                variables: {
                    input: {
                        "warehouse_name": values?.warehouse_name,
                        "address1": values.address1,
                        "city": values.city,
                        "name": values.name,
                        "zipCode": values.zipCode,
                        "phone": values.phone,
                        "stateId": values.stateId,
                    }
                }
            });
            if (data?.insertUpdateReceiver) {
                toast.success("Receiver Added Successfully");
                setPickupAddress('');
                setLocationData('');

                setSelectedPickupAddress(data?.insertUpdateReceiver)
                refetch();
            }
        } catch (err) {
            toast.error(err.message);
        } finally {
            setIsLoading(false);
        }
    }
    const handleChange = (e, setFieldValue) => {
        const cleanedValue = e.target.value.replace(/\s+/g, '');
        setFieldValue('phone', cleanedValue);
      };
    return (
        <>
            <Transition show={show}>
                <Dialog className="relative z-20" onClose={onClose}>
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-30 w-screen h-screen overflow-y-auto">
                        <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative w-screen transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="h-[75vh] overflow-y-auto bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div>
                                            <div className="mb-4">
                                                <h2 className="text-xl font-semibold">Add Pickup Address</h2>
                                            </div>
                                            <p className='border border-gray-100'></p>
                                            <div className="mt-6">
                                                <div>
                                                    <h4 className="font-bold text-gray-800 mb-4">Pickup Contact Details</h4>

                                                    <Formik
                                                        initialValues={{
                                                            warehouse_name: pickupAddress?.warehouse_name || '',
                                                            name: pickupAddress?.name || '',
                                                            phone: pickupAddress?.phone || '',
                                                            address1: pickupAddress?.address1 || '',
                                                            zipCode: pickupAddress?.zipCode || '',
                                                            city: pickupAddress?.city || '',
                                                            stateId: pickupAddress?.stateId?.name || ''
                                                        }}
                                                        validationSchema={validationSchema}
                                                        onSubmit={AddReceiverData}
                                                    >
                                                        {({ setFieldValue }) => (
                                                            <Form>
                                                                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700">Home/ Shop Name/ Your name <span className="text-red-500">*</span></label>
                                                                        <Field
                                                                            type="text"
                                                                            name="warehouse_name"
                                                                            placeholder="Enter warehouse name"
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                        />
                                                                        <ErrorMessage name="warehouse_name" component="div" className="text-red-500 text-sm" />
                                                                    </div>
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700">Full Name <span className="text-red-500">*</span></label>
                                                                        <Field
                                                                            type="text"
                                                                            name="name"
                                                                            placeholder="Enter name"
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                        />
                                                                        <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
                                                                    </div>
                                                                    <div className="md:col-span-2">
                                                                        <h4 className="font-bold text-gray-800 mb-4 mt-3 md:mt-0">Address Details</h4>
                                                                        <label className="block text-sm font-medium text-gray-700">Flat, Housing no., Building, Apartment - Area, street, sector <span className="text-red-500">*</span></label>
                                                                        <Field
                                                                            type="text"
                                                                            name="address1"
                                                                            placeholder="Enter address"
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                        />
                                                                        <ErrorMessage name="address1" component="div" className="text-red-500 text-sm" />
                                                                    </div>

                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700">Pincode <span className="text-red-500">*</span></label>
                                                                        <Field
                                                                            type="text"
                                                                            name="zipCode"
                                                                            placeholder="Enter pincode"
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                            onChange={(event) => handlePincodeChange(event, setFieldValue)}
                                                                        />
                                                                        <ErrorMessage name="zipCode" component="div" className="text-red-500 text-sm" />
                                                                    </div>
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700">City <span className="text-red-500">*</span></label>
                                                                        <Field
                                                                            type="text"
                                                                            name="city"
                                                                            placeholder="Enter city"
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                            value={locationData.city}
                                                                        />
                                                                        <ErrorMessage name="city" component="div" className="text-red-500 text-sm" />
                                                                    </div>
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700">State <span className="text-red-500">*</span></label>
                                                                        <Field
                                                                            type="text"
                                                                            name="stateId"
                                                                            placeholder="Enter state"
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                            value={locationData.state}
                                                                        />
                                                                        <ErrorMessage name="stateId" component="div" className="text-red-500 text-sm" />
                                                                    </div>
                                                 
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700">Mobile Number <span className="text-red-500">*</span></label>
                                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                                            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                                                                +91
                                                                            </span>
                                                                            <Field
                                                                                type="number"
                                                                                name="phone"
                                                                                onChange={(e) => handleChange(e, setFieldValue)}
                                                                                placeholder="Enter mobile number"
                                                                                className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm"
                                                                            />
                                                                        </div>
                                                                        <ErrorMessage name="phone" component="div" className="text-red-500 text-sm" />
                                                                        <p className="mt-1 text-xs text-gray-500">We’ll call this number to coordinate pickup</p>
                                                                    </div>
                                                                    <div className="col-span-1 md:col-span-2">
                                                                        <button
                                                                            type="submit"
                                                                            className=" inline-flex w-full justify-center rounded-md bg-slate-900 px-3 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-slate-900 sm:mt-1"
                                                                            data-autofocus
                                                                            disabled={isLoading}
                                                                        >
                                                                            {isLoading ? (
                                                                                <div className='flex justify-center'>
                                                                                    <span className='me-2'>Add PickUp Address</span>
                                                                                    <SpinLoader />
                                                                                </div>
                                                                            ) : (
                                                                                'Add PickUp Address'
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 space-y-2">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default AddressDetailsModel;

