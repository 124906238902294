import React, { useCallback, useEffect, useState } from 'react';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SIGN_UP, VERIFY_OTP } from '../graphql/Mutation/Signin.js';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import GoogleFacebookButton from '../firebase/facebook-google-button.js';
import Loader from '../components/Loader.js';
import SpinLoader from '../components/SpinLoader.js';
import logoImage from '../asset/img/logo1.png';


const Signup = ({ refetch }) => {
    const navigate = useNavigate();
    const [otpSent, setOtpSent] = useState(false);
    const [SignUp, { loading: signUpLoading }] = useMutation(SIGN_UP);
    const [verifyOtp, { loading }] = useMutation(VERIFY_OTP);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        setCheckboxError(false);
    };
    const handleButtonClick = () => {
        if (!isCheckboxChecked) {
            setCheckboxError('Please Accept Terms and Conditions.');
        } else {
            toggleContent();
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            otp: '',
            ApprovePrivacyPolicy: false,
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid Email address').required('Enter Email Number'),
            otp: otpSent
                ? Yup.string().required('Enter OTP').matches(/^[0-9]{6}$/, 'OTP must be exactly 6 digits') : Yup.string(),
            ApprovePrivacyPolicy: otpSent ? Yup.boolean().oneOf([true], 'You must accept the privacy policy') : Yup.boolean(),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            if (otpSent) {
                handleVerifyOtp(values, setSubmitting);
            } else {
                handleSignUp(values, setSubmitting);
            }
        },
    });

    const handleSignUp = async (values, setSubmitting) => {
        try {
            const { data } = await SignUp({ variables: { email: values.email.trim() } });
            if (data.signUp.success) {
                setOtpSent(true);
                toast.success(data?.signUp?.message);
            } else {
                toast.error(data?.signUp?.message);
            }
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setSubmitting(false);
        }
    };

    const handleVerifyOtp = async (values, setSubmitting) => {
        try {
            if (!isCheckboxChecked) {
                toast.error('You must accept the privacy policy');
            } else {
                const { data } = await verifyOtp({ variables: { email: values?.email, otp: values?.otp, ApprovePrivacyPolicy: isCheckboxChecked } });
                if (data?.verifyOtp?.success) {
                    localStorage.setItem('token', data?.verifyOtp?.token);
                    localStorage.setItem('id', data?.verifyOtp?.user?.id);
                    localStorage.setItem('role', data?.verifyOtp?.user?.userType);
                    await refetch();
                    if (data?.verifyOtp?.user?.userType === 'User') {
                        navigate("/");
                    } else {
                        navigate("/adminhome");

                    }
                    toast.success(data?.verifyOtp?.message);
                    setOtpSent(false);
                    formik.resetForm();
                } else {
                    toast.error(data?.verifyOtp?.message);
                }
            }
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setSubmitting(false);
        }
    };

    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/\s+/g, '');
        setFieldValue(name, cleanedValue);
    };

    const [isContentVisible, setContentVisible] = useState(false);

    const toggleContent = () => {
        setContentVisible(!isContentVisible);
    };

    const isLoading = loading || signUpLoading;
    return (
        <div>
            <Header />
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>

                <div className="mt-12 md:mt-1 lg:ms-8 lg:mt-10 mb-12 justify-center ">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <Link to="/adminhome">
                            <img
                                src={logoImage}
                                className="mx-auto mb-7 h-10 w-auto"
                                alt="Logo"
                            />
                        </Link>

                    </div>
                    <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6" onSubmit={formik.handleSubmit}>

                            <div className='space-y-3'>
                                <GoogleFacebookButton isCheckboxChecked={isCheckboxChecked} setCheckboxError={setCheckboxError} refetch={refetch} />
                                {isContentVisible && (

                                    <div className='mt-4'>
                                        <p className="lg:mt-4 mb-5 font-semibold text-sm text-gray-900">
                                            Register / Login with your Email ID to get started.
                                        </p>
                                        <div className=''>
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                Email ID
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="text"
                                                    placeholder="Enter Email ID"
                                                    autoComplete="email"
                                                    required
                                                    {...formik.getFieldProps('email')}
                                                    onChange={(e) => handleInputChange(e, formik.setFieldValue)}
                                                    className="block focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className="text-red-600 text-sm mt-1">{formik.errors.email}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        {otpSent && (
                                            <div className='mb-8 mt-4'>
                                                <label htmlFor="otp" className="block text-sm font-medium leading-6 text-gray-900">
                                                    OTP
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        id="otp"
                                                        name="otp"
                                                        type="number"
                                                        autoComplete="otp"
                                                        placeholder="Enter OTP"
                                                        required
                                                        {...formik.getFieldProps('otp')}
                                                        onChange={(e) => handleInputChange(e, formik.setFieldValue)}
                                                        className="block focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                    />
                                                    {formik.touched.otp && formik.errors.otp ? (
                                                        <div className="text-red-600 text-sm mt-1">{formik.errors.otp}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        )}

                                        <div>
                                            <button
                                                type="submit"
                                                className={`flex w-full mb-10 mt-5 justify-center rounded-md shadow-sm px-3 py-2 text-sm font-semibold leading-6 text-white 
                                                ${isLoading ? 'bg-slate-700 cursor-not-allowed' : 'bg-slate-900 hover:bg-slate-900'}`}
                                            >
                                                {isLoading ? (
                                                    <SpinLoader />
                                                ) : (
                                                    otpSent ? 'Verify OTP' : 'Request OTP'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                )}


                                <button
                                    type="button"
                                    onClick={handleButtonClick}
                                    className='border bg-gray-50 w-full justify-center items-center flex border-gray-300 py-2 rounded-full font-semibold'
                                >
                                    <p className='ml-3'>Continue with Email</p>
                                </button>

                                <div className={`flex space-x-2 items-center ${checkboxError || (formik.touched.ApprovePrivacyPolicy && formik.errors.ApprovePrivacyPolicy) ? 'border border-red-300 rounded-full py-1 px-3' : ''}`}>
                                    <input
                                        type="checkbox"
                                        id="ApprovePrivacyPolicy"
                                        name="ApprovePrivacyPolicy"
                                        className="h-4 w-4 text-blue-600 rounded"
                                        onChange={(e) => {
                                            handleCheckboxChange(e);
                                            formik.handleChange(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        checked={isCheckboxChecked || formik.values.ApprovePrivacyPolicy}
                                    />
                                    <p htmlFor="ApprovePrivacyPolicy" className='font-semibold text-sm'>Accepted <Link to='/privacyPolicy' className='text-blue-600'> Privacy Policy</Link><Link to='/termsAndConditions' className='text-blue-600'> & Teams</Link></p>
                                </div>
                                {(checkboxError || (formik.touched.ApprovePrivacyPolicy && formik.errors.ApprovePrivacyPolicy)) && (
                                    <p className="text-red-500 text-sm">{checkboxError ? 'Please Accept Terms and Conditions.' : formik.errors.ApprovePrivacyPolicy}</p>
                                )}


                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Signup
