import React from 'react';
import { Dialog, DialogPanel } from '@headlessui/react'; // Ensure you have this library installed
import { useModal } from '../Contex.js';
import { CANCEL_ORDER } from '../graphql/Mutation/Ordre.js';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CREATE_PAYMENT_REFUND } from '../graphql/Mutation/paymentRefund.js';
import { GET_ORDERS, GET_ORDERS_BY_ID } from '../graphql/Query/Order.js';

const Modal = () => {
    const { isOpen, awbNumber, closeModal, ticketId } = useModal();
    const [cancelCourier] = useMutation(CANCEL_ORDER, {
        refetchQueries: [
            {
                query: GET_ORDERS,
                variables: {
                    page: 1,
                    limit: 20,
                    sort: {
                        key: "createdAt",
                        type: -1
                    }
                }
            },
            {
                query: GET_ORDERS_BY_ID,
                variables: {
                    getOrderId: ticketId
                }
            }
        ]
    });
    const [addPaymentRefund] = useMutation(CREATE_PAYMENT_REFUND);
    const handleCancelCourier = async (awbNumber) => {
        try {
            const { data } = await cancelCourier({ variables: { awbNumber } });
            if (data?.cancelCourier) {
                toast.success('Courier cancelled successfully!');
            }
        } catch (error) {
            toast.error(error?.message)
        }
    };
    const handleModalClose = () => {
        closeModal();
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            contact: '',
            message: '',
        },
        validationSchema: Yup.object({
            contact: Yup.string()
                .required('UPI ID or Mobile No are Required')
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const { data } = await addPaymentRefund({
                    variables: {
                        "input": {
                            "upiId": values?.contact,
                            "cancelReason": values?.message,
                            "orderDetails": ticketId
                        }
                    }
                });

                if (data?.addRefundPayment?.status) {
                    handleCancelCourier(awbNumber);
                    resetForm();
                    handleModalClose();
                    toast.success(data?.addRefundPayment?.message);
                } else {
                    toast.error(data?.addRefundPayment?.message);
                }
            } catch (error) {
                toast.error(error);
            }
        },
    });
    return (
        <>


            {isOpen && (
                <Dialog open={isOpen} onClose={handleModalClose} className="relative z-10">
                    <div
                        transition
                        className="fixed inset-0 bg-black bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <h2 className="text-xl font-bold mb-6">Courier Cancel</h2>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div>
                                            <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
                                                UPI ID or Mobile No.<span className="text-red-500">*</span>
                                            </label>
                                            <input
                                                id="contact"
                                                name="contact"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contact}
                                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-slate-700 sm:text-sm"
                                            />
                                            {formik.touched.contact && formik.errors.contact ? (
                                                <div className="text-red-600 text-sm">{formik.errors.contact}</div>
                                            ) : null}
                                        </div>

                                        <div className='my-3'>
                                            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                                Cancel Reason
                                            </label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.message}
                                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-slate-700 sm:text-sm"
                                            />
                                            {formik.touched.message && formik.errors.message ? (
                                                <div className="text-red-600 text-sm">{formik.errors.message}</div>
                                            ) : null}
                                        </div>
                                        <button
                                            type="submit"
                                            className="py-2 mt-4 px-4 border w-full border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-800 hover:bg-slate-900 focus:outline-none"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                                    <button
                                        type="button"
                                        data-autofocus
                                        onClick={handleModalClose}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    );
};

export default Modal;
