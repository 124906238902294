import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import {
    Dialog,
    DialogPanel,
    Transition,
    TransitionChild,
} from '@headlessui/react'
import { SlLocationPin } from "react-icons/sl";
import { GoCircle } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import { Link, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ORDERS } from '../graphql/Query/Order.js';
import { CREATE_PAYMENT_REFUND } from '../graphql/Mutation/paymentRefund.js'
import moment from 'moment';
import NoDataFound from '../components/NoDataFound.js';
import { toast } from 'react-toastify';
import { CANCEL_ORDER } from '../graphql/Mutation/Ordre.js';
import { useModal } from '../Contex.js';
import { GET_ROLE_USER } from '../graphql/Query/Signin.js';
import Label from '../Home/Label.js';
import CustomPagination from '../components/Pagination.js';
import ShipLabel from './ShipLabel.js';

const AllOrders = () => {
   
    const [open, setOpen] = useState(false)
    const [selectedLabel, setSelectedLabel] = useState('');
    const [modalOpenedOnce, setModalOpenedOnce] = useState(false);
    const { openModal, setModalOpen } = useModal();
    const [searchUser, setSearchUser] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [filter, setFilter] = useState('');
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
    const [activeButton, setActiveButton] = useState('All Courier');
    const location = useLocation();
    const id = localStorage.getItem('id');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(15);
    const [orderData, setOrderData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dataLocation = location?.state?.data
    const [addPaymentRefund] = useMutation(CREATE_PAYMENT_REFUND);
    const { data: getUser, refetch: getUserRoleRefetch } = useQuery(GET_ROLE_USER);
    const { data: GetOrderOrder, loading, refetch } = useQuery(GET_ORDERS, {
        variables: {
            page: page + 1,
            limit,
            "sort": {
                "key": "createdAt",
                "type": -1
            },
            customerId: getUser?.me?.id,
            tag: activeButton,
            "filter": filter,
            "from": fromDate,
            "to": toDate,
        },
        fetchPolicy: 'network-only',
    });
    // useEffect(() => {
    //     if (location.state?.shouldRefetch) {
    //         refetch();
    //         setOpen(true);
    //     }
    // }, [location.state, refetch]);
    useEffect(() => {
        const hasModalOpened = localStorage.getItem('modalOpenedOnce');
        if (location.state?.shouldRefetch && !hasModalOpened) {
            refetch().then((response) => {
                // Check if there's data and get the label from the first item
                const latestOrder = response.data?.getOrders?.data?.[0];
                if (latestOrder && latestOrder?.label) {
                    setSelectedLabel(latestOrder?.label);
                }
                setOpen(true); // Open the modal
                localStorage.setItem('modalOpenedOnce', 'true'); 
            });
        }
    }, [location?.state]);
    useEffect(() => {
        if (GetOrderOrder?.getOrders?.data) {
            setOrderData(GetOrderOrder?.getOrders?.data);
        }
    }, [GetOrderOrder]);
    const fromDateChange = (e) => {
        setFromDate(e.target.value);
    }

    const toDateChange = (e) => {
        setToDate(e.target.value);
    }

    const handleSearch = (e) => {
        setFilter(searchUser);
        setPage(0);

    }
    useEffect(() => {
        if (dataLocation) {
            if (dataLocation === 'To me' || dataLocation === 'From me') {
                !getUser?.me?.mobile && setModalOpen(true);
            }
            setActiveButton(dataLocation);
        }
    }, [dataLocation]);
    const handleDownload = (label) => {
        const link = document.createElement('a');
        link.href = label;
        link.download = 'label.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const capitalizeFirstLetter = (text) => {
        if (!text) return text;
        return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };

    const handlePageClick = (selectedPage) => {
        setPage(selectedPage);
        refetch(); // Refetch data with the new page
    };
    return (
        <div>
            <Header />
            <div className="bg-white">
                <div>
                    <Transition show={mobileFiltersOpen}>
                        <Dialog className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                            <TransitionChild
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </TransitionChild>

                            <div className="fixed inset-0 z-40 flex">
                                <TransitionChild
                                    enter="transition ease-in-out duration-300 transform"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transition ease-in-out duration-300 transform"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <DialogPanel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                                        <div className="flex items-center justify-between px-4">
                                            <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                            <button
                                                type="button"
                                                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                                onClick={() => setMobileFiltersOpen(false)}
                                            >
                                                <span className="sr-only">Close menu</span>
                                                <IoClose className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </Dialog>
                    </Transition>

                    <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className='p-4 mt-6 max-w-full mx-auto bg-gray-50 ring-1 ring-gray-200 rounded-xl space-y-4'>
                            <div className='flex justify-between '>
                                <div>
                                    <h2 className="text-xl font-bold">Courier Filter</h2>

                                </div>

                            </div>

                            <div className='lg:flex space-y-7 lg:space-y-0 lg:justify-between'>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">Search</p>
                                    <div className='md:flex md:space-x-4 space-x-0'>
                                        <div>
                                            <input type="text" onChange={(e) => setSearchUser(e.target.value)} onKeyDown={handleSearch} value={searchUser} placeholder="Search..." className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none  px-2 py-1 border rounded-md" />
                                        </div>
                                    </div>
                                </div>
                                <div className='md:flex md:space-x-4 space-x-0 space-y-3 md:space-y-0'>
                                    <div className='cursor-pointer'>
                                        <p className="text-sm font-medium text-gray-700">From</p>
                                        <div className='md:flex md:space-x-4 space-x-0'>
                                            <input
                                                type="date"
                                                className="mt-1 w-full md:w-none  px-2 py-1.5 md:py-1  focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 border rounded-md cursor-pointer"
                                                value={fromDate}
                                                onChange={(e) => { fromDateChange(e) }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-gray-700">To</p>
                                        <div className='md:flex md:space-x-4 space-x-0 '>
                                            <div>
                                                <input
                                                    type="date"
                                                    className="mt-1 cursor-pointer focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none  px-2 py-1.5 md:py-1 border rounded-md"
                                                    value={toDate}
                                                    onChange={(e) => toDateChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section aria-labelledby="products-heading" className="pb-2 pt-6">
                            <h2 id="products-heading" className="sr-only">
                                Products
                            </h2>

                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                                <div className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                                    <div className=' hidden lg:block py-3 bg-gray-50 rounded-lg px-4 cursor-pointer'>
                                        <section className="mb-8">
                                            <h2 className="text-lg font-semibold mb-2">"To Me"</h2>
                                            <p className="mb-4 text-slate-700 font-normal">
                                                <strong className='text-slate-800 font-semibold'>Meaning:</strong> When a shipment is labeled "To Me," it means that the shipment is being sent to you.
                                                Someone else (the sender) has created an order or package and is shipping it to your address.
                                            </p>
                                            <p className="mb-4 text-slate-700 font-normal">
                                                <strong className='text-slate-800 font-semibold'>Example:</strong> If you order a product online and the seller sends the package to your home or office,
                                                that shipment is "To Me." You are the recipient of the courier.
                                            </p>
                                        </section>

                                        <section className="mb-8">
                                            <h2 className="text-lg font-semibold mb-2">"From Me"</h2>
                                            <p className="mb-4 text-slate-700 font-normal">
                                                <strong className='text-slate-800 font-semibold'>Meaning:</strong> When a shipment is labeled "From Me," it means that you are the one sending the shipment
                                                to someone else. You create the order, prepare the package, and arrange for the courier to deliver it to the recipient.
                                            </p>
                                            <p className="mb-4 text-slate-700 font-normal">
                                                <strong className='text-slate-800 font-semibold'>Example:</strong> If you need to send a document to a client or a package to a friend, you create the
                                                shipment, label it, and the courier delivers it to the recipient. In this case, the shipment is "From Me."
                                            </p>
                                        </section>

                                        <section className="mb-8">
                                            <h2 className="text-lg font-semibold mb-2">Application in a Courier Business</h2>
                                            <ul className="list-disc pl-6 space-y-2">
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>Tracking:</strong> You may need to track both incoming and outgoing shipments...</li>
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>Customer Service:</strong> When interacting with customers, it’s important to clarify...</li>
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>Logistics Management:</strong> Managing the flow of shipments in both directions helps...</li>
                                            </ul>
                                        </section>

                                        <section>
                                            <h2 className="text-lg font-semibold mb-2">Summary</h2>
                                            <ul className="list-disc pl-6 space-y-2">
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>To Me:</strong> Refers to shipments sent to you from another sender.</li>
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>From Me:</strong> Refers to shipments you send to another recipient.</li>
                                            </ul>
                                        </section>
                                    </div>
                                </div>

                                <div className="lg:col-span-3 space-y-3 w-full">
                                    <div className="flex space-x-5">
                                        <button
                                            className={` ${activeButton === 'All Courier' ? 'bg-gray-900 text-white px-3 py-1 rounded-lg font-semibold' : 'font-semibold text-gray-400 '}`}
                                            onClick={() => setActiveButton('All Courier')}
                                        >
                                            All Courier
                                        </button>
                                        <button
                                            className={` ${activeButton === 'To me' ? 'bg-gray-900 text-white px-3 py-1 rounded-lg font-semibold' : 'font-semibold text-gray-400 '}`}
                                            onClick={() => {
                                                setActiveButton('To me');
                                                !getUser?.me?.mobile && setModalOpen(true);
                                            }}
                                        >
                                            To me
                                        </button>
                                        <button
                                            className={` ${activeButton === 'From me' ? 'bg-gray-900 text-white px-3 py-1 rounded-lg font-semibold' : 'font-semibold text-gray-400 '}`}
                                            onClick={() => {
                                                setActiveButton('From me');
                                                !getUser?.me?.mobile && setModalOpen(true);
                                            }}
                                        >
                                            From me
                                        </button>
                                    </div>
                                    <p className='border-b'></p>

                                    <div className="mt-4">
                                        <>
                                            <div>
                                                {
                                                    orderData?.length > 0 && orderData ?
                                                        orderData.map((data, index) => {
                                                            return (
                                                                <>

                                                                    {
                                                                        <div key={index} className='p-5 md:grid md:grid-cols-2 grid-cols-1 mb-2 border border-slate-300 rounded-lg cursor-pointer'>
                                                                            <div className='space-y-4'>
                                                                                <p className={`text-md font-semibold ${data?.trackStatus === 'Your courier has been confirmed' ? 'text-green-500' : 'text-red-500'}`}>
                                                                                    {data?.trackStatus}
                                                                                </p>
                                                                                <div className='flex space-x-4'>
                                                                                    <div className='space-y-1 text-gray-400'>
                                                                                        <GoCircle />
                                                                                        <ul className='ms-1.5'>
                                                                                            <li className='text-xs leading-3'>I</li>
                                                                                            <li className='text-xs leading-3'>I</li>
                                                                                            <li className='text-xs leading-3'>I</li>
                                                                                            <li className='text-xs leading-3'>I</li>
                                                                                            <li className='text-xs leading-3'>I</li>
                                                                                            <li className='text-xs leading-3'>I</li>
                                                                                            <li className='text-xs leading-3'>I</li>
                                                                                            <li className='text-xs leading-3 sm:hidden'>I</li>
                                                                                            <li className='text-xs leading-3 sm:hidden'>I</li>
                                                                                        </ul>
                                                                                        <SlLocationPin />
                                                                                    </div>
                                                                                    {data?.awb_number && <Link to='/singletrack' state={{ id: data?.id, activeButton: activeButton }} > <div className='space-y-4 text-ellipsis overflow-hidden'>
                                                                                        <div>
                                                                                            <p className='text-sm font-semibold mb-1'>
                                                                                                {capitalizeFirstLetter(data?.pickupAddress?.name)}
                                                                                                <span className='ms-1 text-gray-400'>(Pickup)</span>
                                                                                            </p>
                                                                                            <div>
                                                                                                <p className='text-sm'>
                                                                                                    {capitalizeFirstLetter(data?.pickupAddress?.address1)}.
                                                                                                </p>
                                                                                                <p className='text-sm'>
                                                                                                    {capitalizeFirstLetter(data?.pickupAddress?.city)}. {capitalizeFirstLetter(data?.pickupAddress?.stateId?.name)} - {data?.pickupAddress?.zipCode}
                                                                                                </p>
                                                                                            </div>

                                                                                            <p className='text-sm text-gray-500'>{data?.pickupAddress?.phone}</p>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className='text-sm font-semibold mb-1'>
                                                                                                {capitalizeFirstLetter(data?.deliveryAddress?.name)}
                                                                                                <span className='ms-1 text-gray-400'>(Delivery)</span>
                                                                                            </p>
                                                                                            <p className='text-sm'>
                                                                                                {capitalizeFirstLetter(data?.deliveryAddress?.address1)}.
                                                                                            </p>
                                                                                            <p className='text-sm'>
                                                                                                {capitalizeFirstLetter(data?.deliveryAddress?.city)}. {capitalizeFirstLetter(data?.deliveryAddress?.stateId?.name)} - {data?.deliveryAddress?.zipCode}
                                                                                            </p>
                                                                                            <p className='text-sm text-gray-500'>{data?.deliveryAddress?.phone}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    </Link>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='md:justify-self-end md:text-end mt-6 md:mt-0 space-y-6'>
                                                                                <div className='md:justify-end'>
                                                                                    <div className='flex space-x-4 md:justify-end'>
                                                                                        {data?.awb_number &&
                                                                                            <Link to='/singletrack' state={{ id: data?.id, activeButton: activeButton }} >
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="rounded-md px-3 py-1.5 text-sm font-semibold text-white bg-slate-900 hover:bg-slate-900 hover:text-white"
                                                                                                >
                                                                                                    Track now
                                                                                                </button>
                                                                                            </Link>
                                                                                        }

                                                                                        {data?.awb_number && data?.trackStatus === 'Your courier has been confirmed' && activeButton === 'All Courier' && <button
                                                                                            onClick={() => { handleDownload(data?.label) }}
                                                                                            type="button"
                                                                                            className="rounded-md px-3 py-1.5 text-sm font-semibold text-white bg-slate-900 hover:bg-slate-900"
                                                                                        >
                                                                                            Download label
                                                                                        </button>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='space-y-3'>
                                                                                    <p className='text-sm  '>Order ID : <span>{data?.order_id}</span></p>
                                                                                    <p className='text-sm '>AWB NO : <span className='font-semibold'>{data?.awb_number}</span></p>
                                                                                </div>

                                                                                <div className='flex space-x-2 md:justify-end'>
                                                                                    <p className='text-md font-bold text-slate-800'>Total Amount ₹{Math.round(data?.order_amount)}</p>
                                                                                </div>
                                                                                <p className='text-sm text-gray-500'>{moment(data?.createdAt).format('DD/MM/YYYY')}</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        }) : (
                                                            <div className='my-20'>
                                                                <NoDataFound />
                                                            </div>
                                                            // )
                                                        )}
                                            </div>
                                        </>
                                    </div>
                                    <CustomPagination
                                        totalItems={GetOrderOrder?.getOrders?.count || 0} // Total number of items
                                        limit={limit}
                                        pageNo={page} // Current page number (zero-based index)
                                        handlePageClick={handlePageClick} // Handler to change page
                                    />
                                    <div className='lg:hidden py-3 bg-gray-50 rounded-lg px-4 cursor-pointer'>
                                        <section className="mb-8">
                                            <h2 className="text-lg font-semibold mb-2">"To Me"</h2>
                                            <p className="mb-4 text-slate-700 font-normal">
                                                <strong className='text-slate-800 font-semibold'>Meaning:</strong> When a shipment is labeled "To Me," it means that the shipment is being sent to you.
                                                Someone else (the sender) has created an order or package and is shipping it to your address.
                                            </p>
                                            <p className="mb-4 text-slate-700 font-normal">
                                                <strong className='text-slate-800 font-semibold'>Example:</strong> If you order a product online and the seller sends the package to your home or office,
                                                that shipment is "To Me." You are the recipient of the courier.
                                            </p>
                                        </section>

                                        <section className="mb-8">
                                            <h2 className="text-lg font-semibold mb-2">"From Me"</h2>
                                            <p className="mb-4 text-slate-700 font-normal">
                                                <strong className='text-slate-800 font-semibold'>Meaning:</strong> When a shipment is labeled "From Me," it means that you are the one sending the shipment
                                                to someone else. You create the order, prepare the package, and arrange for the courier to deliver it to the recipient.
                                            </p>
                                            <p className="mb-4 text-slate-700 font-normal">
                                                <strong className='text-slate-800 font-semibold'>Example:</strong> If you need to send a document to a client or a package to a friend, you create the
                                                shipment, label it, and the courier delivers it to the recipient. In this case, the shipment is "From Me."
                                            </p>
                                        </section>

                                        <section className="mb-8">
                                            <h2 className="text-lg font-semibold mb-2">Application in a Courier Business</h2>
                                            <ul className="list-disc pl-6 space-y-2">
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>Tracking:</strong> You may need to track both incoming and outgoing shipments...</li>
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>Customer Service:</strong> When interacting with customers, it’s important to clarify...</li>
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>Logistics Management:</strong> Managing the flow of shipments in both directions helps...</li>
                                            </ul>
                                        </section>

                                        <section>
                                            <h2 className="text-lg font-semibold mb-2">Summary</h2>
                                            <ul className="list-disc pl-6 space-y-2">
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>To Me:</strong> Refers to shipments sent to you from another sender.</li>
                                                <li className="text-slate-700 font-normal"><strong className='text-slate-800 font-semibold'>From Me:</strong> Refers to shipments you send to another recipient.</li>
                                            </ul>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
                <ShipLabel open={open} setOpen={setOpen} selectedLabel={selectedLabel} />
                <Label />
            </div>
            <Footer />
        </div>
    )
}

export default AllOrders
