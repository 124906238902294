import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ORDER_MENIFETCH } from '../graphql/Mutation/Ordre.js';
import SpinLoader from '../components/SpinLoader.js';
import { toast } from 'react-toastify';
import { useRef } from 'react';


const SuccessPayment = () => {
  const navigate = useNavigate();
  const [orderMenifetch] = useMutation(ORDER_MENIFETCH);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const orderId = query.get('order_id');
  const callMade = useRef(false);
  const fetchPaymentStatus = async () => {
    try {
      const response = await fetch(`https://api.uk-logistic.com/order-status/${orderId}`);
      // const response = await fetch(`http://localhost:8000/order-status/${orderId}`);
      const data = await response.json();
      let getOrderResponse = data;
      let orderStatus;

      if (getOrderResponse.filter(transaction => transaction.payment_status === "SUCCESS").length > 0) {
        orderStatus = "Success"
        // console.log("calll=====================11111111111111")
      } else if (getOrderResponse.filter(transaction => transaction.payment_status === "PENDING").length > 0) {
        orderStatus = "Pending"
      } else {
        orderStatus = "Failure"
      }

      setPaymentStatus(orderStatus);
    } catch (error) {
      // console.log('✌️error --->', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // console.log("calll=====================")
    if (orderId && !callMade.current) {
      callMade.current = true;
      fetchPaymentStatus();
    }
  }, [orderId]);

  useEffect(() => {
    // console.log("calll=====================00000000000000");
    let isCalled = false;
    if (paymentStatus === 'Success' && orderId && !isCalled) {
      localStorage.removeItem('modalOpenedOnce');
      isCalled = true;
      // console.log("calll=====================00000000000000++++++++++")
      const callOrderMenifetch = async () => {
        try {
          const { data } = await orderMenifetch({
            variables: {
              orderId: orderId
            }
          });
          if (data?.orderMenifetch) {
            setTimeout(() => {
              navigate('/allOrders', { state: { shouldRefetch: true } });
            }, 2000);
          }
        } catch (error) {
        }
      };
      callOrderMenifetch();
    } else if (paymentStatus === 'Failure') {
      toast.error('Payment failed. Please try again.')
      setTimeout(() => {
        navigate('/allOrders', { state: { shouldRefetch: true } });
      }, 2000);
    } else if (paymentStatus === 'Pending') {
      toast.error('Payment pending. Please try again.')
      setTimeout(() => {
        navigate('/allOrders', { state: { shouldRefetch: true } });
      }, 2000);
    }
  }, [paymentStatus, orderId]);

  const backgroundColor = loading ? 'bg-white' :
    paymentStatus === 'Success' ? 'bg-green-700' :
      paymentStatus === 'Pending' ? 'bg-white' :
        'bg-slate-700';
  return (
    <div className={`${backgroundColor} h-screen w-screen flex justify-center items-center`}>
      <div className='text-center'>
        {loading ? (

          <SpinLoader classNamecolor='true' />

        ) : (
          <>
            <p className='font-semibold text-white text-3xl animate-bounce mb-6'>Courier {paymentStatus}!</p>
            {paymentStatus === 'Success' && <div> <Link to="/"> <button
              className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-900 text-white hover:bg-gray-900 focus:outline-none text-sm"
            >
              {/* <FaCalculator className="text-white me-2 text-lg" /> */}
              Go to Dashboard            </button></Link></div>}
            {paymentStatus === 'Failure' && <div><Link to="/"> <button
              className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-900 text-white hover:bg-gray-900 focus:outline-none text-sm"
            >
              {/* <FaCalculator className="text-white me-2 text-lg" /> */}
              Go to Dashboard            </button></Link></div>}
            {paymentStatus === 'Pending' && <div><Link to="/"> <button
              className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-900 text-white hover:bg-gray-900 focus:outline-none text-sm"
            >
              {/* <FaCalculator className="text-white me-2 text-lg" /> */}
              Go to Dashboard            </button></Link></div>}

            {/* {paymentStatus === 'Success' && <Lottie animationData={l} loop={false} className='h-64 w-64' />}
            {paymentStatus === 'Failure' && <Lottie animationData={cancelblack} loop={false} className='h-64 w-64' />}
            {paymentStatus === 'Pending' && <Lottie animationData={pending} loop={false} className='h-64 w-64' />} */}
          </>
        )}
      </div>
    </div>
  );
}

export default SuccessPayment;
