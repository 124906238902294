import React, { useEffect, useRef, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import './Accodion.css';
import { ReactComponent as Images1 } from '../asset/img/p1.svg';

import s2Image from '../asset/img/s2.png';
import s1Image from '../asset/img/s1.png';
import s33Image from '../asset/img/s33.png';
import s4Image from '../asset/img/s4.png';
import s66Image from '../asset/img/s66.png';
import s9Image from '../asset/img/s9.png';
import s88Image from '../asset/img/s88.png';
import tableImage from '../asset/img/table.png';
import p1Image from '../asset/img/p1.svg';

const data = [
  {
    question: "Selection of Boxes",
    features: [
      {
        name: 'Material',
        description: '● Use corrugated boxes of 3 ply for products below 4Kg and not fragile',
        description1: '● Use corrugated boxes of 5 ply for products above 4Kg and fragile',
        description2: '● Use tamper evident boxes for high value items (Cost above Rs. 7000)',
        description3: '● Ensure the material used can withstand the required edgecrush and burst factor loads',
        description4: '● Optimize the size of the boxes as per the product dimensions',
        description5: '● The box should not be too small or too big for the item shipped. An optimum size box should be chosen',
        description6: '● Inspect the box for holes, tears, crushed edges and sturdiness. Avoid poor quality boxes for the safety of the item shipped',
      },
      {
        name: 'Size', description: '● Optimize the size of the boxes as per the product dimensions',
        description1: '● The box should not be too small or too big for the item shipped. An optimum size box should be chosen',
      },
      { name: 'Quick Check', description: '● Inspect the box for holes, tears, crushed edges and sturdiness. Avoid poor quality boxes for the safety of the item shipped' },
    ],
     images :[
      { Images1:p1Image },
  ]

  },
  {
    question: "Selection of Flyers/Polybags",
    description: "Flyers are quick and handy in packing of flexible items such as apparels or can be used as an added protection for the safety of the shipment from external fluids, tampering etc.",
    features: [
      {
        name: 'Material',
        description: '● Choose 60-80microns hotmelt glue adhesive LDPE polybags',
        description1: '● Use tamper evident polybags for high value items (Cost above Rs.7000)',
      },
      { name: 'Quick Check', description: '● Inspect the polybag for any damages', description1: '● Ensure the opening is sealed properly after the packing of item' },
    ],
    images : [
      { Images1: s1Image},
  ]

  },
  {
    question: "Packing of item",
    description: "Packing an item involves leak proofing, cushioning, placing of the item and filling of the voids in the box. Enough cushioning needs to be provided to ensure no loads are transferred to the item.",
    features: [
      {
        name: 'Material',
        description: '● Bubble Wrap',
        description1: '● Foam sheets',
      },
    ],
    images: [
      { Images1: s2Image },
    ]

  },
  {
    question: "Leaking Proofing",
    description: "Liquid products need to be made leak proof before cushioning or packing. Common leak proofing techniques are mentioned below.",
    features: [
      {
        name: 'Material',
        description: '● Heat shrink sleeves',
        description1: '● Taping of bottle caps',
        description2: '● Zip lock or leak proof pouches',
      },
    ],
    images: [
      { Images1: s33Image },
    ]

  },
  {
    question: "Sealing of Shipment",
    description: "Sealing ensures that the properly packed item is secured and intact inside the box throughout the journey of the shipment.",
    features: [
      {
        name: 'Tape', description: '● Use Pressure Sensitive Tapes such as BOPP',
      },
      { name: 'Locations', description: '● Seal the center seam of the top and bottom of the box', description1: '● For heavy shipments, apply H-Taping by sealing both the center and edge seams on the box' },
    ],
    images: [
      { Images1: s4Image },
    ]
  },
  {
    question: "Shipment labelling",
    description: "Proper labeling is crucial for faster and safe handling of the shipment in reaching the intended destination.",
    features: [
      {
        name: 'Essential data',
        description: '● Barcode height (min. size : 8 mm)',
        description1: '● Waybill number and Barcode (min. size : 8 MIL, 1MIL= 0.2032mm)',
        description2: '● Order number and Barcode (min. size : 8 MIL, 1MIL= 0.2032mm)',
        description3: '● Consignee address (min. font size: 8pts, Calibri body)',
        description4: '● Return address (min. font size: 8pts, Calibri body)',
        description5: '● Retail/Tax Invoice',
      },
      {
        name: 'Location of Label', description: '● Paste the label on a seamless flat face of the box/flyer',
        description1: '● Paste the label away from seams or edges of the box',
        description2: '● Readability of barcode is important for faster and correct processing of shipments',
        description3: '● In flyers/polybags, all the essential data on labels should be visible and the label should be placed flat',
        description4: '● The pocket containing the label in flyer should be taped at the opening to avoid the loss of label',
        description5: '● Thermal printed labels are strongly recommended over regular labels'
      },
    ],
    images: [
      { Images1: s66Image },
    ],
    imagess: [
      { Images2: s9Image, name: 'What not to do' },
    ]

  },
  {
    question: "Special handling labels",
    description: "Special handling labels helps in communicating the necessary information for appropriately handling the shipment.",
    features: [
      { name: 'Common categories', description: '● Fragile', description1: '● Perishables', description2: '● Dangerous goods', description3: '● Package orientation', description4: '● Keep dry' },
    ],
    images: [
      { Images1: s88Image },

    ]
  },
  // {
  //   question: "Internal Packing material vs functionality",
  //   description: "Internal Packing material vs functionality",
  //   features: [
  //   ],
  //   images: [
  //     { Images1: tableImage },
  //   ]
  // },
  // {
  //   question: "Product categories",
  //   description: "Special handling labels helps in communicating the necessary information for appropriately handling the shipment.",
  //   features: [
  //     {
  //       name: 'Electronic items',
  //       description: '● Mobile phones & accessories',
  //       description1: '● Tablets',
  //       description2: '● Laptop & Computer accessories',
  //     },
  //     {
  //       name: 'Electrical items', description: '● Small home appliances',
  //       description1: '● Power equipment',
  //       description2: '● Industrial equipment',
  //     },
  //     {
  //       name: 'Fragile items',
  //       description: '● Glassware',
  //       description1: '● Crockery',
  //       description2: '● Home decor',
  //     },
  //     {
  //       name: 'Apparels',
  //       description: '● Flexible packaging',
  //       description1: '● Rigid packaging',
  //     },
  //     {
  //       name: 'Fashion accessories',
  //       description: '● Jewelry',
  //       description1: '● Watches',
  //     },
  //     {
  //       name: 'Liquid items',
  //       description: '● Beauty & Personal Care',
  //       description1: '● Food products, Medicines',
  //       description2: '● Automotive',
  //     },
  //     {
  //       name: 'Others',
  //       description: '● Books & stationery',
  //       description1: '● Travel gears',
  //     },
  //   ],
  //   images: [
  //   ]

  // },
];

const AccordionItem = ({ question, answer, isOpen, onClick, data }) => {
  const contentHeight = useRef();
  return (
    <>
      <div className="wrapper mb-7">
        <button
          className={`question-container ${isOpen ? "active" : ""}`}
          onClick={onClick}
        >
          <p className="question-content
        ">{question}</p>
          <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} />
        </button>

        <div
          ref={contentHeight}
          className="answer-container"
          // style={
          //   isOpen
          //     ? { height: contentHeight.current.scrollHeight }
          //     : { height: "0px" }
          // }
        >
          <div className="answer-content">
            <div className="bg-white">
              <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-1 py-7  sm:px-6 sm:py-10 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{data?.title}</h2>
                  <p className="my-4 text-gray-500">
                    {data?.description}
                  </p>

                  <dl className="space-y-10">
                    {data?.features.map((feature) => (
                      <div key={feature.name}>
                        <dt className="font-medium mb-4 text-gray-900">{feature.name}</dt>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description1}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description2}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description3}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description4}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description5}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description6}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description7}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description8}</dd>
                        <dd className="mt-1 text-sm text-gray-500">{feature.description9}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
                <div className="">
                  {
                    data?.images?.map((image, index) => {
                      return (
                        <div key={ index}>
                        <img
                          src={image?.Images1}
                          className="rounded-xl"
                        />
                        </div>

                      )
                    })
                  }
                </div>
              </div>
              {
                data?.imagess?.map((image, index) => {
                  return (
                    <div key={ index}>
                      <dt className="font-medium mb-4 text-gray-900">● {image.name}</dt>
                      <img
                        src={image?.Images2}
                        className="rounded-xl"
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
          data={item}
        />
      ))}
    </div>
  );
};

export default Accordion;
