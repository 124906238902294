import React, { useEffect } from 'react'
import { useState } from 'react'
import {
    Dialog,
    DialogPanel,
    Transition,
    TransitionChild,
} from '@headlessui/react'
import { IoClose } from "react-icons/io5";
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import { Link, useLocation } from 'react-router-dom';
import { GET_ADVERTISE, UPDATE_NOTIFICATION_STATUS } from '../graphql/Query/Advertise.js';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import Pagination from '../components/Pagination.js';
import NoDataFound from '../components/NoDataFound.js';
import Loader from '../components/Loader.js';
import truckImage from '../asset/img/truck1.png';
import CustomPagination from '../components/Pagination.js';


// const filters = [
//     {
//         id: 'color',
//         name: 'Color',
//         options: [
//             { value: 'white', label: 'White', checked: false },
//             { value: 'beige', label: 'Beige', checked: false },
//             { value: 'blue', label: 'Blue', checked: true },
//             { value: 'brown', label: 'Brown', checked: false },
//             { value: 'green', label: 'Green', checked: false },
//             { value: 'purple', label: 'Purple', checked: false },
//         ],
//     },
//     {
//         id: 'category',
//         name: 'Category',
//         options: [
//             { value: 'new-arrivals', label: 'New Arrivals', checked: false },
//             { value: 'sale', label: 'Sale', checked: false },
//             { value: 'travel', label: 'Travel', checked: true },
//             { value: 'organization', label: 'Organization', checked: false },
//             { value: 'accessories', label: 'Accessories', checked: false },
//         ],
//     },
//     {
//         id: 'size',
//         name: 'Size',
//         options: [
//             { value: '2l', label: '2L', checked: false },
//             { value: '6l', label: '6L', checked: false },
//             { value: '12l', label: '12L', checked: false },
//             { value: '18l', label: '18L', checked: false },
//             { value: '20l', label: '20L', checked: false },
//             { value: '40l', label: '40L', checked: true },
//         ],
//     },
// ]

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

const Notification = () => {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
    const [page, setPage] = useState(0);
    // const [limit, setLimit] = useState(10);
    const limit = 15;
    const [updateNotification] = useMutation(UPDATE_NOTIFICATION_STATUS, {
        refetchQueries: [{
            query: GET_ADVERTISE, variables: {
                page: page,
                limit,
                sort: {
                    key: "createdAt",
                    type: -1
                }
            }
        }]
    });
    const { data: getAdvertise, loading, refetch } = useQuery(GET_ADVERTISE, {
        variables: {
            page: page + 1,
            limit,
            sort: {
                key: "createdAt",
                type: -1
            },
        },
        fetchPolicy: 'cache-and-network'
    });
    useEffect(() => {
        if (getAdvertise && getAdvertise?.getBlogs?.data) {
            const ticketIds = getAdvertise?.getBlogs?.data.map(ad => ad?.id);
            updateNotification({
                variables: {
                    id: ticketIds
                }
            });
        }
    }, [getAdvertise]);
    const handlePageClick = (selectedPage) => {
        setPage(selectedPage); // Update the current page
        // refetch(); // Refetch the data with the new page number
      };
    return (
        <div>
            <Header />
            <div className="bg-white">
                <div>
                    <Transition show={mobileFiltersOpen}>
                        <Dialog className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                            <TransitionChild
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </TransitionChild>

                            <div className="fixed inset-0 z-40 flex">
                                <TransitionChild
                                    enter="transition ease-in-out duration-300 transform"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transition ease-in-out duration-300 transform"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <DialogPanel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                                        <div className="flex items-center justify-between px-4">
                                            <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                            <button
                                                type="button"
                                                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                                onClick={() => setMobileFiltersOpen(false)}
                                            >
                                                <span className="sr-only">Close menu</span>
                                                <IoClose className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </Dialog>
                    </Transition>
                    <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white">
                        <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-10">
                            <h1 className="text-xl font-bold tracking-tight text-gray-900">All Notification</h1>
                        </div>
                        <section aria-labelledby="products-heading" className="pb-4 pt-6">
                            <h2 id="products-heading" className="sr-only">
                                Products
                            </h2>
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                                <form className="mt-2 hidden lg:block">
                                    <img src={truckImage} className="rounded-xl hover:opacity-80" alt="Truck" />

                                </form>
                                <div className="lg:col-span-3 space-y-3">
                                    <Link to='/singletrack'>
                                    </Link>
                                    <Link to='/singletrack'>
                                    </Link>
                                    {
                                        loading ? (
                                            <div className="flex justify-center items-center my-28">
                                                <Loader />
                                            </div>
                                        ) : (
                                            getAdvertise?.getBlogs?.count > 0 ? getAdvertise?.getBlogs?.data.map((d, index) => {
                                                return (
                                                    <div key={index} className='flex flex-col md:flex-row bg-white cursor-pointer'>
                                                        <img src={d?.image} className='hover:opacity-80 rounded-lg h-80 w-80' />
                                                        <div className='flex flex-col justify-center md:ms-6 ms-0 md:py-2 md:pr-3 mt-3 md:mt-0'>
                                                            <p className='font-bold text-lg mb-4'>{d?.title}</p>
                                                            <p className=''>{d?.description}</p>
                                                            <p className='text-xs my-4 text-gray-400'>{moment(d?.createdAt).format('DD/MM/YYYY')}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            ) :
                                                (
                                                    <div className='flex justify-center items-center h-96'>
                                                        <div>
                                                            <NoDataFound />
                                                        </div>
                                                    </div>
                                                ))}
                                    <div className='justify-end flex mt-7 mb-9'>
                                    <div>
                                    <CustomPagination
        totalItems={getAdvertise?.getBlogs?.count || 0} // Total number of items
        limit={limit}
        pageNo={page} // Current page number (zero-based index)
        handlePageClick={handlePageClick} // Handler to change page
      />
</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Notification
