import React, { useEffect, useState } from 'react'
import AdminHeader from './AdminHeader.js'
import { Link, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SUPPORT, UPDATE_SUPPORT } from '../graphql/Query/GetSupport.js';
import moment from 'moment';
// import Pagination from '../components/Pagination.js';
import NoDataFound from '../components/NoDataFound.js';
import CustomPagination from '../components/Pagination.js';
const AdminTicket = () => {
    const location = useLocation();
    const todayTicket = location?.state?.dailyCountSupport
    const [isOn, setIsOn] = useState({});
    const [searchUser, setSearchUser] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [filter, setFilter] = useState('');
    const [activeButton, setActiveButton] = useState('open');
    // const [ setActiveData] = useState([]);
    // const [ setActiveFalse] = useState([]);
    const [active, setActive] = useState();
    const [todayTicketCount, setTodayTicketCount] = useState(0);

    const [updateSupport] = useMutation(UPDATE_SUPPORT);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(200);
    const { data, refetch } = useQuery(GET_SUPPORT, {
        variables: {
            page: page + 1,
            limit,
            sort: {
                key: "createdAt",
                type: -1
            },
            filter: filter,
            from: fromDate,
            to: toDate,
            status: active
        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (data?.getSupport?.data) {
            const supportData = data.getSupport.data;
            const active = supportData.filter((item) => item.isActive === true);
            const inactive = supportData.filter((item) => item.isActive === false);
            const today = moment().startOf('day');
            const todayTickets = supportData.filter((ticket) => moment(ticket.createdAt).isSame(today, 'day'));

            // setActiveData(active);
            // setActiveFalse(inactive);
            setTodayTicketCount(todayTickets.length);
            filterData();
        }
    }, [data, activeButton]);

    const filterData = () => {
        if (data?.getSupport?.data) {
            if (activeButton === 'open') {
                setActive(true);
            } else if (activeButton === 'close') {
                setActive(false);
            }
        }
    };

    const toggleSwitch = async (id) => {
        try {
            const newIsOn = !isOn[id];
            const { data } = await updateSupport({ variables: { updateSupportId: id, isActive: newIsOn } });
            if (data.updateSupport) {
                refetch();
                setIsOn(prev => ({ ...prev, [id]: newIsOn }));
            } else {
                console.error('Failed to update support status');
            }
        } catch (error) {
            console.error('Error updating support status:', error);
        }
    };

    const fromDateChange = (e) => {
        setFromDate(e.target.value);
    }

    const toDateChange = (e) => {
        setToDate(e.target.value)
    }

    const searchCustomerUser = () => {
        setFilter(searchUser)
    }

    const handleSearch = (e) => {
        searchCustomerUser()
    }

    const handleReset = () => {
        setFromDate('');
        setToDate('');
        setFilter('');
        setSearchUser('');
    }

    const handlePageClick = (selectedPage) => {
        setPage(selectedPage);
    };

    return (
        <div>
            <div>
                <AdminHeader />
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 my-10 space-y-10">
                    <div className='md:flex space-y-7 md:space-y-0 justify-between'>
                        <div className='flex space-x-6'>
                            <h2 className="text-lg font-bold text-gray-900">All Ticket</h2>
                            <div className="lg:col-span-3 space-y-3">
                                <div className="flex space-x-5">
                                    <button
                                        className={` ${activeButton === 'open' ? 'bg-green-600 text-white px-3 py-1 rounded-lg font-semibold' : 'font-semibold text-gray-400 '}`}
                                        onClick={() => { setActiveButton('open'); setPage(0) }}
                                    >
                                        Open
                                    </button>
                                    <button
                                        className={` ${activeButton === 'close' ? 'bg-red-600 text-white px-3 py-1 rounded-lg font-semibold' : 'font-semibold text-gray-400 '}`}
                                        onClick={() => { setActiveButton('close'); setPage(0) }}
                                    >
                                        Closed
                                    </button>
                                </div>
                            </div>
                        </div>
                        <h2 className="text-lg font-semibold">Today Ticket - {todayTicket}</h2>
                    </div>
                    <div className='p-4 max-w-full mx-auto bg-gray-50 ring-1 ring-gray-200 rounded-xl space-y-4'>
                        <div className='flex justify-between'>
                            <h2 className="text-lg font-semibold">Orders Filters</h2>
                            <button className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300" onClick={handleReset}>
                                Reset
                            </button>
                        </div>

                        <div className='lg:flex space-y-7 lg:space-y-0 lg:justify-between'>
                            <div>
                                <p className="text-sm font-medium text-gray-700">Search</p>
                                <div className='md:flex md:space-x-4 space-x-0'>
                                    <div>
                                        <input type="text" onChange={(e) => setSearchUser(e.target.value)} onKeyDown={handleSearch} value={searchUser} placeholder="Search..." className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none p-1.5 border rounded-md" />
                                    </div>
                                </div>
                            </div>

                            <div className='md:flex md:space-x-4 space-x-0 space-y-3 md:space-y-0'>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">From</p>
                                    <div className='md:flex md:space-x-4 space-x-0'>
                                        <div>
                                            <input
                                                type="date"
                                                className="mt-1 w-full md:w-none p-1.5 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 border rounded-md"
                                                value={fromDate}
                                                onChange={(e) => { fromDateChange(e) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">To</p>
                                    <div className='md:flex md:space-x-4 space-x-0'>
                                        <div>
                                            <input
                                                type="date"
                                                className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none p-1.5 border rounded-md"
                                                value={toDate}
                                                onChange={(e) => toDateChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt-4">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 my-10 space-y-6">
                    <div className='flex justify-between'>
                        <h2 className="text-lg font-semibold">Open Ticket</h2>
                        <div className='flex'>
                            {/* <div className='mt-2'>
                                <label>
                                    Items per page:
                                    <select value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
                                    <option value={100}>100</option>
                                    <option value={50}>50</option>
                                    </select>
                                </label>
                            </div> */}

                            {/* <div>
                                <Pagination
                                    pageCount={data?.getSupport?.data && Math.ceil(data?.getSupport?.count / limit)}
                                    pageNo={page}
                                    handlePageClick={(data) => handlePageClick(data)}
                                />
                            </div> */}
                            <CustomPagination
                                totalItems={data?.getSupport?.count || 0}
                                limit={limit}
                                pageNo={page}
                                handlePageClick={handlePageClick}
                            />

                        </div>

                    </div>
                    <div className="overflow-x-auto rounded-t-xl  border border-gray-200">

                        <p className='font-semibold m-3'>Total : {data?.getSupport?.count}</p>
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">No.</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Order Date</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">AWB No.</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Customer Name</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Mobile No</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Customer Message</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Ticket Status</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Full Details</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.getSupport?.data && data?.getSupport?.count > 0 ? (
                                    data?.getSupport?.data?.map((row, index) => (
                                        <tr key={index} className='hover:bg-gray-50'>
                                            <td className="py-2.5 text-sm px-4 border-b">{index + 1}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{moment(row?.createdAt).format('DD/MM/YYYY')}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.awbNo}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.name}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.mobile}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">{row?.message}</td>
                                            <td className="py-2.5 text-sm px-4 border-b">
                                                <button onClick={() => toggleSwitch(row?.id)}
                                                    className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300"
                                                >
                                                    Close
                                                </button></td>
                                            <td className="py-2.5 text-sm px-4 border-b text-blue-400 font-semibold"><Link to='/admintrack' state={{ id: row?.order?.id }}> View Details</Link></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9">
                                            <NoDataFound />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminTicket
