import { gql } from "@apollo/client";

export const GET_REFUND_PAYMENT = gql`
query GetPaymentRes($page: Int, $limit: Int, $filter: String, $sort: userSort, $search: String, $from: String, $to: String, $status: String) {
        getPaymentRes(page: $page, limit: $limit, filter: $filter, sort: $sort, search: $search, from: $from, to: $to, status: $status) {
            count
            data {
                id
                upiId
                cancelReason
                createdAt
                isActive
                customerId {
                    mobile
                }
                orderDetails {
                    id
                    status
                    order_number
                    pickupAddress {
                        name
                        phone
                        address1
                        city
                        stateId {
                            id
                            name
                        }
                        zipCode
                    }
                    deliveryAddress {
                        name
                        phone
                        address1
                        city
                        stateId {
                            id
                            name
                        }
                        zipCode
                    }
                    order_amount
                    awb_number
                    paymentSessionId
                    paymentStatus
                    orderId
                    order_id
                    trackStatus
                    courier_id
                    paymentDetails
                }
                isDeleted
                createdBy
                updatedBy
            }
        }
    }
`

export const GET_DAILY_COUNT_PAYMENTREFUND = gql`
query MonthlyPaymentRefund {
  getPaymentCounts {
    monthlyPaymentRefund {
      count
      _id
    }
    dailyPaymentRefund {
      count
      _id
    }
  }
}
`