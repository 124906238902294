import { gql } from "@apollo/client";

export const  CREATE_PAYMENT_REFUND = gql`
mutation AddRefundPayment($input: createInputData) {
  addRefundPayment(input: $input) {
    status
    message
    paymentRefund {
      id
      upiId
      cancelReason
      createdAt
      isActive
      customerId {
        mobile
      }
      orderDetails {
        status
        order_number
        pickupAddress {
          name
          phone
          address1
          city
          stateId {
            id
            name
          }
          zipCode
          customerId
        }
        deliveryAddress {
          name
          phone
          address1
          city
          zipCode
          customerId
        }
        order_amount
        awb_number
        paymentSessionId
        paymentStatus
        orderId
        order_id
        trackStatus
        courier_id
        paymentDetails
      }
      isDeleted
      createdBy
      updatedBy
    }
  }
}
`
export const UPDATE_PAYMENT_STATUS = gql`
mutation UpdateRefundPayment($updateRefundPaymentId: String, $isActive: Boolean) {
  updateRefundPayment(id: $updateRefundPaymentId, isActive: $isActive)
}
`