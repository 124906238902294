import React, { useState } from 'react';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import { useMutation } from '@apollo/client';
import { CREATE_SUPPORT } from '../graphql/Mutation/Support.js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import f33Image from '../asset/img/f1.jpg';
const AnotherHelp = () => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [handleinputsupport] = useMutation(CREATE_SUPPORT);

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(40, 'Must be 40 characters or less')
                .required('Enter First name'),
            mobile: Yup.string()
                .required('Enter Phone number')
                .matches(/^[0-9]+$/, 'Must be only digits')
                .min(10, 'Must be exactly 10 digits')
                .max(10, 'Must be exactly 10 digits'),
            message: Yup.string()
                .max(200, 'Must be 200 characters or less')
                .required('Enter Message'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const data = await handleinputsupport({
                    variables: {
                        input: {
                            name: values?.name,
                            mobile: values?.mobile,
                            message: values?.message,
                        },
                    },
                });
                if (data?.data?.createSupport?.status) {
                    toast.success(data?.data?.createSupport?.message);
                    resetForm();
                    setIsSubmitDisabled(true);
                }
            } catch (err) {
                toast.error(err.message);
            }
        },
    });

    return (
        <div>
            <Header />

            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-10">
                <img
                    alt=""
                    src={f33Image}
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                />

                <div
                    aria-hidden="true"
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    />
                </div>
                <div
                    aria-hidden="true"
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div>
                        <div className="justify-center max-w-full px-4 sm:px-9 md:grid md:max-w-7xl md:grid-rows-[auto,auto,1fr] md:gap-x-8 md:px-8 md:py-3">
                            <div className="lg:row-span-10">
                                <div className="lg:grid lg:grid-cols-1">
                                    <div className="isolate">
                                        <div className="mx-auto max-w-7xl">
                                            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-xl">Raise a Query</h2>
                                            <p className="mt-2 text-sm text-white">
                                                Hello, Welcome to UK's Support Center, How can we help you today?
                                            </p>
                                        </div>

                                        <form onSubmit={formik.handleSubmit} className="mx-auto mt-10 max-w-7xl md:mb-16 sm:my-8">
                                            <div className="grid grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="name" className="block text-sm font-semibold leading-6 text-white">
                                                        First name <span className="text-red-400">*</span>
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            placeholder="Enter your first name"
                                                            className={`focus:outline-none focus:ring-white focus:border-white w-full rounded-md bg-transparent border-0 px-3.5 py-2 text-white ring-1 ring-inset ring-white placeholder:text-white focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${formik.touched.name && formik.errors.name ? 'border-red-500' : ''}`}
                                                            {...formik.getFieldProps('name')}
                                                        />
                                                        {formik.touched.name && formik.errors.name ? (
                                                            <div className="text-red-400 text-sm">{formik.errors.name}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="mobile" className="block text-sm font-semibold leading-6 text-white">
                                                        Phone number <span className="text-red-400">*</span>
                                                    </label>
                                                    <div className="relative mt-2.5">
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-white text-white sm:text-sm">
                                                                +91
                                                            </span>
                                                            <input
                                                                type="tel"
                                                                name="mobile"
                                                                id="mobile"
                                                                placeholder="Enter your phone number"
                                                                className={`block focus:outline-none rounded-r-md focus:ring-white bg-transparent focus:border-white w-full px-4 py-2 text-white ring-1 ring-inset ring-white placeholder:text-white focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${formik.touched.mobile && formik.errors.mobile ? 'border-red-500' : ''}`}
                                                                {...formik.getFieldProps('mobile')}
                                                            />
                                                        </div>
                                                        {formik.touched.mobile && formik.errors.mobile ? (
                                                            <div className="text-red-400 text-sm">{formik.errors.mobile}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                                                        Message <span className="text-red-400">*</span>
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <textarea
                                                            name="message"
                                                            id="message"
                                                            rows={4}
                                                            placeholder="Enter your message"
                                                            className={`block focus:outline-none bg-transparent focus:ring-white focus:border-white w-full rounded-md border-0 px-3.5 py-2 text-white ring-1 ring-inset ring-white placeholder:text-white focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${formik.touched.message && formik.errors.message ? 'border-red-500' : ''}`}
                                                            {...formik.getFieldProps('message')}
                                                        />
                                                        {formik.touched.message && formik.errors.message ? (
                                                            <div className="text-red-400 text-sm">{formik.errors.message}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-10">
                                                <button
                                                    type="submit"
                                                    className="block w-full rounded-md bg-white px-3.5 py-2.5 text-center text-sm font-semibold text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                                                    disabled={isSubmitDisabled}
                                                >
                                                    Sent Now
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


                <div className="mx-auto max-w-7xl px-6 lg:px-8 py-6">
                    <h1 className="text-2xl font-bold mb-6 text-gray-800">Logistics and Parcel Enquiries</h1>

                    <div className="space-y-6">
                        {/* 1. Shipment Tracking */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">1. Shipment Tracking:</h2>
                            <p className="text-gray-600">
                                Customers often ask for the current status of their parcel, including its location and estimated delivery time. This type of enquiry usually involves tracking numbers or order IDs.
                            </p>
                        </section>

                        {/* 2. Rate Enquiry */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">2. Rate Enquiry:</h2>
                            <p className="text-gray-600">
                                Businesses or individuals may inquire about the cost of shipping based on parcel size, weight, destination, and delivery speed. This helps in budgeting for shipments.
                            </p>
                        </section>

                        {/* 3. Customs and Documentation */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">3. Customs and Documentation:</h2>
                            <p className="text-gray-600">
                                Enquiries related to international shipping often involve questions about customs regulations, required documentation (like invoices, declarations), and duties or taxes.
                            </p>
                        </section>

                        {/* 4. Delivery Time Estimation */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">4. Delivery Time Estimation:</h2>
                            <p className="text-gray-600">
                                Customers frequently ask for the estimated time of arrival (ETA) of their parcel based on the service type and route chosen.
                            </p>
                        </section>

                        {/* 5. Damaged or Lost Parcels */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">5. Damaged or Lost Parcels:</h2>
                            <p className="text-gray-600">
                                Customers may raise enquiries if their parcels are damaged during transit or go missing. This leads to investigations and claims for refunds or replacements.
                            </p>
                        </section>

                        {/* 6. Parcel Pickup and Drop-off Locations */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">6. Parcel Pickup and Drop-off Locations:</h2>
                            <p className="text-gray-600">
                                Enquiries might focus on the availability of pickup points, drop-off locations, or collection schedules, especially for couriers offering these services.
                            </p>
                        </section>

                        {/* 7. Service Availability */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">7. Service Availability:</h2>
                            <p className="text-gray-600">
                                Questions about whether certain locations are covered by the delivery service, both domestically and internationally, are common, especially for remote or rural areas.
                            </p>
                        </section>

                        {/* 8. Packaging Guidelines */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">8. Packaging Guidelines:</h2>
                            <p className="text-gray-600">
                                Enquiries related to proper packaging methods, materials, and requirements to ensure safe delivery of the parcel.
                            </p>
                        </section>

                        {/* 9. Bulk Shipments */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">9. Bulk Shipments:</h2>
                            <p className="text-gray-600">
                                Businesses may inquire about special rates or services for shipping large volumes of parcels, along with logistics solutions like warehousing, supply chain management, or freight services.
                            </p>
                        </section>

                        {/* 10. Returns and Refunds */}
                        <section>
                            <h2 className="text-xl font-semibold text-gray-700">10. Returns and Refunds:</h2>
                            <p className="text-gray-600">
                                Customers may ask about return policies, the process for sending back parcels, and how to request refunds for failed or unsatisfactory deliveries.
                            </p>
                        </section>
                    </div>
                </div>


            <Footer />
        </div>
    );
};

export default AnotherHelp;
