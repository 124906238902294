import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { FaShoppingCart, FaTruck, FaThumbsUp, FaChevronDown, FaChevronUp, FaExclamationCircle, FaRegCheckCircle } from 'react-icons/fa';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { BsBoxSeam } from 'react-icons/bs';
import { LiaTruckMovingSolid } from 'react-icons/lia';
import { MdDirectionsBike } from 'react-icons/md';
import { SlClose } from 'react-icons/sl';

const TrackingHistoryModal = ({ trackingInfo, modalOpen, setModalOpen }) => {
    const iconRefs = useRef([]);
    const [lineHeights, setLineHeights] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const handleToggle = () => setShowMore(!showMore);
    useEffect(() => {
        const heights = iconRefs.current.map((ref, index) => {
            if (index < iconRefs.current.length - 1) {
                const currentIcon = ref.getBoundingClientRect();
                const nextIcon = iconRefs.current[index + 1].getBoundingClientRect();
                return nextIcon.top - currentIcon.bottom;
            }
            return 0;
        });
        setLineHeights(heights);
    }, [trackingInfo, showMore]);
    let statusOrder;

    switch (trackingInfo?.status) {
        case 'cancelled':
            statusOrder = ['PP', 'CL'];
            break;
        case 'rto':
            statusOrder = ['PP', 'RT'];
            break;
        default:
            statusOrder = ['PP', 'IT', 'OFD', 'DL'];
            break;
    }

    const getLatestStatusCode = (history) => {
        if (history.length > 0) {
            return history[history.length - 1].status_code;
        }
        return null;
    };

    const visibleStatuses = showMore ? statusOrder : statusOrder.slice(0, 3);

    const getStatus = (status) => {
        switch (status) {
            case 'PP':
                return 'Courier Confirmed';
            case 'IT':
                return 'In Transit';
            case 'OFD':
                return 'Out for Delivery';
            case 'DL':
                return (<><p>Delivery Successfully</p><p className="text-sm text-gray-500 font-normal">Your item has been delivered</p></>);
            case 'CL':
                return trackingInfo.status === "cancelled" ? <> <p>Courier Cancelled</p> <p className='text-xs text-gray-500'>Your order has been cancelled.</p> <p className='text-xs text-gray-500'>You have cancelled this order.</p> </> : '';
            case 'RT':
                return trackingInfo.status === "rto" ? <> <p>Courier RTO</p> <p className='text-xs text-gray-500'>Your order has been cancelled.</p> <p className='text-xs text-gray-500'>You have cancelled this order.</p> </> : '';
            default:
                return '';
        }
    };


    const getIconColor = (history, targetStatus, isCancelled, rto) => {
        if (isCancelled && targetStatus === 'CL') {
            return 'bg-red-500 border-red-200';
        }
        if (rto && targetStatus === 'RT') {
            return 'bg-red-500 border-red-200';
        }

        const latestStatusCode = getLatestStatusCode(history);
        const latestIndex = statusOrder.indexOf(latestStatusCode);
        const targetIndex = statusOrder.indexOf(targetStatus);

        if (latestIndex >= targetIndex) {
            return 'bg-green-500 border-green-200';
        } else {
            return 'bg-gray-400 border-gray-200';
        }
    };
    const getLineColor = (history, targetStatus, isCancelled, rto) => {
        if (isCancelled && targetStatus === 'CL') {
            return 'bg-green-400';
        }
        if (isCancelled && targetStatus === 'RT') {
            return 'bg-green-400';
        }

        const latestStatusCode = getLatestStatusCode(history);
        const latestIndex = statusOrder.indexOf(latestStatusCode);
        const targetIndex = statusOrder.indexOf(targetStatus);

        if (latestIndex >= targetIndex) {
            return 'bg-green-500';
        } else {
            return 'bg-gray-400';
        }
    };


    return (
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)} className="relative z-10">
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel className="relative transform overflow-hidden w-full rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="w-full mx-auto p-7 border border-gray-300 rounded-md bg-white max-h-[70vh] overflow-y-auto">
                            <p className="text-xl font-bold mb-6">Order Tracking</p>
                            <p className='mb-4 text-sm'><strong className='font-medium'>● AWB NO :</strong>  {trackingInfo?.awb_number}</p>
                            <p className='mb-4 text-sm'><strong className='font-medium'>● Courier Date :</strong> {trackingInfo?.created}</p>
                            <p className='mb-4 text-sm'><strong className='font-medium'>● Courier Date :</strong> {trackingInfo?.status}</p>
                            <p className='mb-8 text-sm'><strong className='font-medium'>● Payment Type :</strong> Prepaid</p>

                            <p className="text-xl font-bold mb-6">Track Now</p>

                            {trackingInfo && (
                                <>
                                    <div className="relative flex flex-col items-start space-y-8">
                                        {statusOrder.map((status, index) => {
                                            const isCancelled = trackingInfo?.status === "cancelled";
                                            const rto = trackingInfo?.status === "rto";

                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="flex items-center space-x-4">
                                                        <div className="relative">
                                                            <div ref={(el) => iconRefs.current[index] = el} className={`${getIconColor(trackingInfo?.history, status, isCancelled, rto)} ${trackingInfo?.status === 'pending pickup' && status === 'PP' ? 'bg-green-500 border-green-200' : ''} border-4 text-white p-1.5 rounded-full`}>
                                                                {status === 'PP' && <BsBoxSeam />}
                                                                {status === 'IT' && <LiaTruckMovingSolid />}
                                                                {status === 'OFD' && <MdDirectionsBike />}
                                                                {status === 'DL' && <FaRegCheckCircle />}
                                                                {status === 'CL' && isCancelled && <SlClose />}
                                                                {status === 'RT' && rto && <SlClose />}
                                                            </div>
                                                            {index < statusOrder.length - 1 && (
                                                                <div
                                                                    style={{ height: `${lineHeights[index]}px` }}
                                                                    className={`${getLineColor(trackingInfo?.history, statusOrder[index + 1], isCancelled, rto)} absolute left-1/2 transform -translate-x-1/2 w-px`}
                                                                />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <h2 className="font-medium text-sm">{getStatus(status)}</h2>
                                                            <p className="text-sm text-gray-500">
                                                                {trackingInfo?.history.find(hist => hist.status_code === status)?.event_time || ''}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {!isCancelled && showMore && trackingInfo?.history
                                                        .filter(hist => hist.status_code === status)
                                                        .reverse()
                                                        .map((hist, histIndex) => (
                                                            <div key={histIndex} className="flex items-start ml-8 space-x-4">
                                                                <div className="w-px bg-gray-300" style={{ height: 'auto' }}></div>
                                                                <div>
                                                                    <p className="text-sm font-semibold">{hist.event_time}</p>
                                                                    <p className="text-sm text-gray-500">{hist.location}</p>
                                                                    <p className="text-sm text-gray-500">{hist.message}</p>
                                                                </div>
                                                            </div>
                                                        ))}

                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                    <button onClick={handleToggle} className="mt-7 text-black ml-7 flex font-semibold text-sm focus:outline-none">
                                        {showMore ? 'SHOW LESS' : 'SHOW MORE'} <span className='self-center text-xl'>{showMore ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                onClick={() => setModalOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                            >
                                Cancel
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>

    );
};

export default TrackingHistoryModal;
